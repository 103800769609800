/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { OrganizationRef } from '../models/OrganizationRef';
import type { PageableOrganizations } from '../models/PageableOrganizations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationService {
    /**
     * Add a new organization
     * Add a new organization
     * @param requestBody Create a new organization
     * @returns Organization Successful operation
     * @throws ApiError
     */
    public static postOrganizations(
        requestBody: Organization,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get organizations
     * Get organizations
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param name name of the organization
     * @param email email of the organization
     * @param taxNumber tax number of the organization
     * @returns PageableOrganizations Successful operation
     * @throws ApiError
     */
    public static getOrganizations(
        size?: number,
        page?: number,
        sort?: string,
        name?: string,
        email?: string,
        taxNumber?: string,
    ): CancelablePromise<PageableOrganizations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'name': name,
                'email': email,
                'taxNumber': taxNumber,
            },
        });
    }
    /**
     * Update organization by id
     * Update organization by id
     * @param requestBody Create a new organization
     * @returns Organization Successful operation
     * @throws ApiError
     */
    public static putOrganizations(
        requestBody: Organization,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get organizations
     * Get organizations
     * @returns OrganizationRef Successful operation
     * @throws ApiError
     */
    public static getOrganizationsRef(): CancelablePromise<Array<OrganizationRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/ref',
        });
    }
    /**
     * Deletes a pet
     * delete a pet
     * @param id Organization id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteOrganizations(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get organization by id
     * Get organization by id
     * @param id id of the organization
     * @returns Organization Successful operation
     * @throws ApiError
     */
    public static getOrganizations1(
        id: string,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }
}
