import { ProcessMeasurementType, Role, Status } from 'ui/api/gen';

export const ROLE_LABEL = {
    [Role.PM_MANAGER]: 'Менаџер',
    [Role.PM_ADMIN]: 'Администратор',
    [Role.PM_OPERATOR]: 'Оператор',
    [Role.PM_CLUSTER]: 'Текстилен кластер',
};

export const PROCESS_MEASUREMENT_TYPE = {
    [ProcessMeasurementType.COMPLETE]: 'Комплетно мерење',
    [ProcessMeasurementType.PARTIAL]: 'Парцијално мерење',
};

export const STATUS_LABEL = {
    [Status.DRAFT]: 'Драфт',
    [Status.ACTIVE]: 'Активен',
};

export const MANDATORY = {
    true: 'Задолжително',
    false: 'Опционално',
};
