import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert,
    Typography,
    Box,
    AlertTitle,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ErrorOverlayWithAlertProps = {
    open: boolean;
    onClose: () => void;
};

const ErrorOverlayWithAlert: React.FC<ErrorOverlayWithAlertProps> = ({ open, onClose }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ padding: 0, display: 'flex', width: '100%' }}>
            <Alert
                action={
                    <IconButton aria-label="close" color="error" size="small" onClick={onClose}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{
                    width: '100%',
                    margin: 0,
                    padding: 2,
                }}
                color="error"
            >
                <AlertTitle>Грешка</AlertTitle>
                Настана неочекувана грешка. Ве молиме обидете се повторно подоцна.
            </Alert>
        </DialogTitle>
    </Dialog>
);

export default ErrorOverlayWithAlert;
