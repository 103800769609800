import React from 'react';
import { OpenAPI } from 'ui/api/gen';
import { hasAuthParams, useAuth } from 'react-oidc-context';

export function AuthBootstrapper({ children }) {
    const auth = useAuth();

    React.useEffect(
        () =>
            auth.events.addUserSignedOut(() => {
                auth.signinRedirect();
            }),
        [auth],
    );

    React.useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth]);

    OpenAPI.TOKEN = auth.user?.access_token;

    return auth.isAuthenticated && children;
}
