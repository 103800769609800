/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageableProcessMeasurements } from '../models/PageableProcessMeasurements';
import type { ProcessMeasurement } from '../models/ProcessMeasurement';
import type { ProcessMeasurementInput } from '../models/ProcessMeasurementInput';
import type { ProcessMeasurementPatch } from '../models/ProcessMeasurementPatch';
import type { ProcessMeasurementType } from '../models/ProcessMeasurementType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessMeasurementService {
    /**
     * Get all measurements of a process
     * Get all measurements of a process
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param organizationId id of the organization
     * @param completed is the measurement complete
     * @param processId id of the process
     * @param type
     * @returns PageableProcessMeasurements Get all measurements
     * @throws ApiError
     */
    public static getProcessesMeasurements(
        size?: number,
        page?: number,
        sort?: string,
        organizationId?: string,
        completed?: boolean,
        processId?: string,
        type?: ProcessMeasurementType,
    ): CancelablePromise<PageableProcessMeasurements> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/measurements',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'organizationId': organizationId,
                'completed': completed,
                'processId': processId,
                'type': type,
            },
        });
    }
    /**
     * Get all measurements of a process
     * Get all measurements of a process
     * @param id Id of the process that is measured.
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param completed is the measurement complete
     * @returns PageableProcessMeasurements Get all measurements of a process
     * @throws ApiError
     */
    public static getProcessesMeasurements1(
        id: string,
        size?: number,
        page?: number,
        sort?: string,
        completed?: boolean,
    ): CancelablePromise<PageableProcessMeasurements> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{id}/measurements',
            path: {
                'id': id,
            },
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'completed': completed,
            },
        });
    }
    /**
     * Create a measurement for a process
     * Create a measurement for a process
     * @param id Id of the process that is measured.
     * @param requestBody The attribute to be updated
     * @returns ProcessMeasurement Successful operation
     * @throws ApiError
     */
    public static postProcessesMeasurements(
        id: string,
        requestBody: ProcessMeasurementInput,
    ): CancelablePromise<ProcessMeasurement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/processes/{id}/measurements',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete single measurements of a process
     * Delete single measurements of a process
     * @param id Id of the measurement
     * @returns void
     * @throws ApiError
     */
    public static deleteProcessMeasurements(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/process/measurements/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Update single measurements of a process
     * Update single measurements of a process
     * @param id Id of the measurement
     * @param requestBody The attribute to be updated
     * @returns ProcessMeasurement Get all measurements of a process
     * @throws ApiError
     */
    public static patchProcessMeasurements(
        id: string,
        requestBody: ProcessMeasurementPatch,
    ): CancelablePromise<ProcessMeasurement> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/process/measurements/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get single process measurements by id
     * Get single process measurements by id
     * @param id Process Measurement id
     * @returns ProcessMeasurement Get all measurements of a process
     * @throws ApiError
     */
    public static getProcessMeasurements(
        id: string,
    ): CancelablePromise<ProcessMeasurement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process/measurements/{id}',
            path: {
                'id': id,
            },
        });
    }
}
