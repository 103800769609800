/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Material } from '../models/Material';
import type { MaterialRef } from '../models/MaterialRef';
import type { PageableMaterials } from '../models/PageableMaterials';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialService {
    /**
     * Get all materials of organization
     * Get all materials of organization
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param organizationId id of the organization
     * @param name name of the product
     * @returns PageableMaterials Get all materials of an organization
     * @throws ApiError
     */
    public static getMaterials(
        size?: number,
        page?: number,
        sort?: string,
        organizationId?: string,
        name?: string,
    ): CancelablePromise<PageableMaterials> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/materials',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'organizationId': organizationId,
                'name': name,
            },
        });
    }
    /**
     * Create an material for a organization
     * Create an material for a organization
     * @param requestBody
     * @returns Material Successful operation
     * @throws ApiError
     */
    public static postMaterials(
        requestBody: Material,
    ): CancelablePromise<Material> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all materials of organization
     * Get all materials of organization
     * @param organizationId
     * @returns MaterialRef Get all materials of an organization
     * @throws ApiError
     */
    public static getMaterialsRef(
        organizationId?: string,
    ): CancelablePromise<Array<MaterialRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/materials/ref',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Delete an material
     * Delete an material
     * @param id Material id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteMaterials(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/materials/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get a material by id
     * Get a material by id
     * @param id Material id to retrieve
     * @returns Material Successful operation
     * @throws ApiError
     */
    public static getMaterials1(
        id: string,
    ): CancelablePromise<Material> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/materials/{id}',
            path: {
                'id': id,
            },
        });
    }
}
