import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Menu, MenuItem } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import OrganizationChooser from 'ui/pages/organizations/OrganizationChooser';
import { Role } from 'ui/api/gen';

type Props = {
    onDrawerToggle: () => void;
};

export default function Header({ onDrawerToggle }: Props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const {
        state: { user, organization },
    } = React.useContext(ApplicationContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = React.useCallback(() => {
        handleClose();
        navigate(`/profile`);
    }, [navigate, user]);

    const handleLogout = React.useCallback(() => {
        auth.removeUser();
        auth.signoutRedirect({
            id_token_hint: auth.user?.id_token,
            post_logout_redirect_uri: window.location.href,
        });
    }, [auth]);

    return (
        <AppBar sx={{ bgcolor: '#081627' }} position="sticky" elevation={0}>
            <Toolbar>
                <Grid container spacing={1} alignItems="center">
                    {user?.role !== Role.PM_CLUSTER && <OrganizationChooser />}
                    <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
                        <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Avatar sx={{ bgcolor: 'white', color: '#081627' }}>
                                    {user?.firstName?.charAt(0)}
                                </Avatar>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleNavigate}>Профил</MenuItem>
                                <MenuItem onClick={handleLogout}>Одјави се</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
