import * as React from 'react';
import { Organization, OrganizationService } from 'ui/api/gen';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ConfirmationDialog from 'ui/components/dialog/ConfirmationDialog';
import { useSpinner } from 'ui/hooks/spinner';
import { useErrorHandler } from 'ui/utils/hooks';

const OrganizationsDetails = () => {
    const [displayDialog, setDisplayDialog] = React.useState(false);
    const [organization, setOrganization] = React.useState<Organization | undefined>();

    const navigate = useNavigate();
    const handleError = useErrorHandler();
    const withSpinner = useSpinner();

    const { id } = useParams();

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (id) {
                    const result = await OrganizationService.getOrganizations1(id);
                    setOrganization(result);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [id, withSpinner, handleError]);

    const handleDeleteOrganization = React.useCallback(() => {
        const save = async () => {
            try {
                if (organization) {
                    await OrganizationService.deleteOrganizations(organization.id);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            } finally {
                navigate(`/organizations`);
            }
        };
        withSpinner(save);
    }, [withSpinner, organization, navigate, handleError]);

    if (!organization) {
        return (
            <Paper
                elevation={0}
                sx={{
                    padding: 3,
                    width: '100%',
                    borderRadius: 0,
                    margin: 'auto',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h5" color="primary" component="span">
                    Организацијата не е пронајдена
                </Typography>
            </Paper>
        );
    }
    const { name, email, address } = organization;

    return (
        <div>
            <Paper elevation={0} sx={{ padding: 3, width: '100%', margin: 'auto' }} square>
                <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                        src="https://via.placeholder.com/200"
                        alt={`${name} logo`}
                        sx={{ width: 120, height: 120, marginRight: 2 }}
                    />
                    <Typography variant="h4">{name}</Typography>
                </Box>
                <Typography variant="body1" gutterBottom>
                    <strong>Даночен број:</strong> {123323453322}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Емаил:</strong> {email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Адреса:</strong> {`${address?.addressLine}, ${address?.city}, ${address?.zipCode}`}
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
            </Paper>
            <br />
            <ConfirmationDialog
                onConfirm={handleDeleteOrganization}
                onCancel={() => setDisplayDialog(false)}
                open={displayDialog}
            />
            <span>&nbsp;</span>
        </div>
    );
};

export default OrganizationsDetails;
