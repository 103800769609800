import { format, parseISO } from 'date-fns';

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export type IsoDate = string;

export const formatDateToISOFormat = (date?: string): IsoDate => {
    // @ts-ignore
    const parsedDate = parseISO(date);
    return format(parsedDate, ISO_DATE_FORMAT);
};

export const secondsToHMS = (seconds: number) => {
    if (seconds === null || seconds === undefined) {
        return null;
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const isWhole = Number.isInteger(secs);

    const formattedSeconds = isWhole
        ? `${Math.round(secs).toString().padStart(2, '0')}`
        : secs.toFixed(2).padStart(5, '0');

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${formattedSeconds}`;
};
