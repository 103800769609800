import React from 'react';
import { Avatar, Typography, Grid, Paper, Divider, Container, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { ROLE_LABEL } from 'ui/utils/enums';
import { AuthService } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { useErrorHandler } from 'ui/utils/hooks';
import FullscreenSpinner from 'ui/components/spinner/Spinner';

const getInitials = (firstName?: string, lastName?: string): string =>
    `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase();

const FullScreenContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: '50px',
}));

const ProfileCard = styled(Paper)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 600,
    padding: theme.spacing(4),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

const DetailRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const Label = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    textAlign: 'right',
    paddingRight: theme.spacing(2),
}));

const UserProfile = () => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    const {
        state: {
            user,
            shared: { spinner },
        },
    } = React.useContext(ApplicationContext);

    const { firstName, lastName, email, role = '' } = user;

    const initials = getInitials(firstName, lastName);

    const handleResetPassword = React.useCallback(() => {
        const update = async () => {
            try {
                await AuthService.postAuthResetPassword();
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };

        withSpinner(update);
    }, [withSpinner, handleError]);

    return (
        <FullScreenContainer>
            {!!spinner && <FullscreenSpinner />}
            <ProfileCard elevation={0}>
                <StyledAvatar color="secondary" sx={{ bgcolor: '#0099da' }}>
                    {initials}
                </StyledAvatar>
                <Typography variant="h5" component="div" sx={{ marginTop: 2, textAlign: 'center' }}>
                    {firstName} {lastName}
                </Typography>
                <Divider sx={{ marginTop: 2 }} />
                <Grid container direction="column" spacing={2} sx={{ marginTop: 2 }}>
                    <DetailRow container>
                        <Grid item xs={4}>
                            <Label variant="body1">Корисник:</Label>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                {firstName} {lastName}
                            </Typography>
                        </Grid>
                    </DetailRow>
                    <DetailRow container>
                        <Grid item xs={4}>
                            <Label variant="body1">Е-Мејл:</Label>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{email}</Typography>
                        </Grid>
                    </DetailRow>
                    <DetailRow container>
                        <Grid item xs={4}>
                            <Label variant="body1">Улога:</Label>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{ROLE_LABEL[role]}</Typography>
                        </Grid>
                    </DetailRow>
                    <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                        <Typography variant="caption" color="textSecondary">
                            Профил
                        </Typography>
                    </Box>
                    <Button
                        sx={{ width: 200, alignSelf: 'center' }}
                        onClick={handleResetPassword}
                        color="secondary"
                        variant="contained"
                    >
                        Ресетирај лозинка
                    </Button>
                </Grid>
            </ProfileCard>
        </FullScreenContainer>
    );
};

export default UserProfile;
