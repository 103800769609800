import * as React from 'react';
import Materials from 'ui/pages/configuration/materials/Materials';
import Products from 'ui/pages/configuration/products/Products';
import Employees from 'ui/pages/configuration/employees/Employees';
import TabsComponent from 'ui/components/tabs/TabsComponent';

const TABS = {
    EMPLOYEES: {
        id: 'EMPLOYEES',
        label: 'Вработени',
        component: Employees,
        active: true,
    },
    MATERIALS: {
        id: 'MATERIALS',
        label: 'Материјали',
        component: Materials,
        active: false,
    },
    PRODUCTS: {
        id: 'PRODUCTS',
        label: 'Продукти',
        component: Products,
        active: false,
    },
};

const UsersPage = () => <TabsComponent tabs={TABS} />;

export default UsersPage;
