import React from 'react';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

const FullscreenContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
`;

function FullscreenSpinner() {
    return (
        <FullscreenContainer>
            <CircularProgress color="primary" />
        </FullscreenContainer>
    );
}

export default FullscreenSpinner;
