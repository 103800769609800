import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Button, Grid } from '@mui/material';
import { showErrorOnBlur, TextField, makeValidate } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { Organization } from 'ui/api/gen';
import { object, string, number } from 'yup';

type Props = {
    open: boolean;
    handleClose: () => void;
    onSubmit: (values: any) => void;
    organization?: Organization;
};

const AddOrganization = ({ organization, open, handleClose, onSubmit }: Props) => {
    const schema = object({
        name: string().required(),
        email: string().required().email(),
        taxNumber: string().required().length(15),
        address: object().shape({
            addressLine: string().required(),
            city: string().required(),
            zipCode: number().lessThan(10000).moreThan(999),
        }),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Додади организација</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{ ...organization }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Име на компанијата"
                                        name="name"
                                        showError={showErrorOnBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Е-Маил"
                                        name="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="taxNumber"
                                        label="Даночен број"
                                        name="taxNumber"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="addressLine2"
                                        label="Адреса"
                                        name="address.addressLine"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="city"
                                        label="Град"
                                        name="address.city"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        type="number"
                                        id="zipCode"
                                        label="Зип Код"
                                        name="address.zipCode"
                                    />
                                </Grid>

                                <Grid item xs={12} />
                            </Grid>

                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddOrganization;
