import * as React from 'react';
import {
    Activity,
    ActivityMeasurement,
    ActivityMeasurementService,
    ActivityService,
    EmployeeRef,
    EmployeeService,
    PageableActivities,
    PageableActivityMeasurements,
    Process,
    ProcessMeasurement,
    ProcessMeasurementService,
    ProcessMeasurementType,
    ProcessService,
} from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import Measurement from 'ui/pages/processmeasurements/Measurement';
import { secondsToHMS } from 'ui/utils/date';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Chip, Link, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import { useErrorHandler } from 'ui/utils/hooks';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ColumnFilter } from 'ui/utils/datagrid';
import TreeViewTableComponent from 'ui/components/grid/TreeViewTableComponent';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { routes } from 'ui/routing';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { MANDATORY } from 'ui/utils/enums';
import Quantity from 'ui/pages/processmeasurements/Quantity';
import DurationOverlay from 'ui/pages/processmeasurements/DurationOverlay';

const Container = styled('div')`
    margin: 5px;
`;

const initial = {
    pageIndex: 0,
    pageSize: 10,
    sort: 'createdAt,asc',
    columnFilters: [] as ColumnFilter[],
};

const ActivityMeasurements = () => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [currentMeasurement, setCurrentMeasurement] = React.useState<ActivityMeasurement | undefined>();
    const [durationOverlay, setDurationOverlay] = React.useState(false);
    const [activityMeasurements, setActivityMeasurements] = React.useState<PageableActivityMeasurements>();
    const [activities, setActivities] = React.useState<PageableActivities>();
    const [process, setProcess] = React.useState<Process>();
    const [employees, setEmployees] = React.useState<EmployeeRef[]>([]);
    const [selectedValues, setSelectedValues] = React.useState({});
    const [processMeasurement, setProcessMeasurement] = React.useState<ProcessMeasurement>();
    const [filters, setFilters] = React.useState(initial);
    const navigate = useNavigate();

    useDeepCompareEffect(() => {
        if (activityMeasurements?.result && activityMeasurements?.result.length > 0 && employees.length > 0) {
            const initialState = activityMeasurements?.result.reduce((acc, item) => {
                const employee = employees.find((e) => e.id === item.employeeId);

                if (employee) {
                    // @ts-ignore
                    acc[item.activityId] = { id: employee?.id, label: employee?.name };
                }
                return acc;
            }, selectedValues);

            setSelectedValues(initialState);
        }
    }, [activityMeasurements, employees, selectedValues]);

    const handleSelectChange = (itemId, selectedId) => {
        setSelectedValues((prevState) => ({
            ...prevState,
            [itemId]: selectedId, // Update selected value for specific item
        }));
    };

    const { id } = useParams();

    const employeeData = React.useMemo(
        () =>
            employees.map((item) => ({
                id: item.id,
                label: item.name,
            })),
        [employees],
    );

    const handleUpdate = React.useCallback(async () => {
        const save = async () => {
            try {
                if (id) {
                    const response =
                        await ActivityMeasurementService.getProcessesMeasurementsActivitiesMeasurements(id);
                    setActivityMeasurements(response);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };

        withSpinner(save);
    }, [withSpinner, id, handleError]);

    const handleToggleOverlay = React.useCallback(({ original: { measurement } }) => {
        setDurationOverlay(true);
        setCurrentMeasurement(measurement);
    }, []);

    const handleDelete = React.useCallback(
        ({ original: { measurement } }) => {
            const update = async () => {
                try {
                    await ActivityMeasurementService.deleteProcessesMeasurementsActivitiesMeasurements(measurement?.id);

                    await handleUpdate();
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(update);
        },
        [handleUpdate, withSpinner, handleError],
    );

    const actions = React.useMemo(
        () => ({
            UPDATE: { label: 'Ажурирај', callback: handleToggleOverlay },
            DELETE: { label: 'Избриши', callback: handleDelete },
        }),
        [handleToggleOverlay, handleDelete],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Активност',
                grow: 15,
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ cell, row }) => <div style={{ paddingLeft: `${row.depth * 25}px` }}>{cell.getValue()}</div>,
            },
            {
                accessorKey: 'baseline',
                header: 'Очекувано времетраење по единица',
                grow: 1,
                enableColumnFilter: false,
                enableSorting: false,
                accessorFn: ({ baseline }) => secondsToHMS(baseline),
            },
            {
                accessorKey: 'measurement',
                header: 'Измерено времетраење по единица',
                grow: 1,
                enableColumnFilter: false,
                enableSorting: false,

                accessorFn: ({ measurement }) => {
                    if (!measurement) {
                        return null;
                    }
                    return secondsToHMS(measurement.duration / measurement.quantity);
                },
            },
            {
                id: 'measurementTotal',
                header: 'Измерено времетраење',
                grow: 1,
                enableColumnFilter: false,
                enableSorting: false,
                accessorFn: ({ measurement }) => secondsToHMS(measurement?.duration),
            },
            {
                accessorKey: 'difference',
                header: 'Разлика',
                grow: 1,
                enableColumnFilter: false,
                enableSorting: false,
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    const isNegative = value < 0;
                    const absoluteSeconds = Math.abs(value);

                    const formattedTime = secondsToHMS(absoluteSeconds);
                    const color = isNegative ? 'red' : 'green';
                    const sign = isNegative ? '+' : '-';

                    if (!value) {
                        return null;
                    }

                    return (
                        <span style={{ color }}>
                            {sign}
                            {formattedTime}
                        </span>
                    );
                },
            },
            {
                id: 'quantity',
                header: 'Количина',
                Cell: ({ row }) => {
                    if (row.original.children?.length !== 0) {
                        return null;
                    }
                    return <Quantity measurement={row.original.measurement} disabled={processMeasurement?.completed} />;
                },
                grow: 1,
            },
            {
                id: 'employee',
                header: 'Вработен',
                Cell: ({ row }) => {
                    if (row.original.children?.length !== 0) {
                        return null;
                    }

                    return (
                        <Autocomplete
                            disabled={row.original.measured || processMeasurement?.completed}
                            options={employeeData}
                            onChange={(event, newValue) => handleSelectChange(row.original.id, newValue)}
                            value={selectedValues[row.original.id]}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Вработен" variant="standard" />}
                        />
                    );
                },
                grow: 2,
            },
            {
                grow: 1,
                accessorKey: 'mandatory',
                header: 'Статус',
                filterVariant: 'checkbox',
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ row }) => {
                    const label = MANDATORY[row.original.mandatory];
                    const color = row.original.mandatory ? 'success' : undefined;

                    if (row.original.children?.length !== 0) {
                        return null;
                    }

                    return <Chip color={color} label={label} />;
                },
            },
            ...(!processMeasurement?.completed
                ? [
                      {
                          id: 'measurements',
                          header: 'Мерење',
                          grow: 3,
                          Cell: ({ row, cell, table }) => {
                              if (
                                  row.original.children.length !== 0 ||
                                  processMeasurement?.completed ||
                                  row.original.measurement?.duration
                              ) {
                                  return null;
                              }
                              return (
                                  <Measurement
                                      activityId={row.original.id}
                                      employee={selectedValues[row.original.id]}
                                      processMeasurementId={id}
                                      handleUpdate={handleUpdate}
                                  />
                              );
                          },
                      },
                  ]
                : []),
            {
                accessorKey: 'Акции',
                header: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 10,
                Cell: ({ row }) => {
                    if (processMeasurement?.completed || !row.original.measurement?.duration) {
                        return null;
                    }
                    return <ActionMenu params={row} actions={actions} />;
                },
            },
        ],
        [employeeData, id, handleUpdate, selectedValues, actions, processMeasurement],
    );

    const handleCompleteMeasurement = React.useCallback(async () => {
        if (id) {
            try {
                await ProcessMeasurementService.patchProcessMeasurements(id, { complete: true });

                const processMeasurementResponse = await ProcessMeasurementService.getProcessMeasurements(id);
                setProcessMeasurement(processMeasurementResponse);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            } finally {
                const path = id ? `${routes.MEASUREMENT.path}/${id}` : `${routes.MEASUREMENT.path}`;
                navigate(path);
            }
        }
    }, [handleError, id, navigate]);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (id) {
                    const response =
                        await ActivityMeasurementService.getProcessesMeasurementsActivitiesMeasurements(id);
                    setActivityMeasurements(response);

                    const employeeResponse = await EmployeeService.getEmployeesRef();
                    setEmployees(employeeResponse);

                    const processMeasurementResponse = await ProcessMeasurementService.getProcessMeasurements(id);
                    setProcessMeasurement(processMeasurementResponse);

                    const { pageSize, pageIndex, sort } = filters;
                    const activitiesResponse = await ActivityService.getProcessesActivities(
                        processMeasurementResponse?.processId,
                        pageSize,
                        pageIndex,
                        sort,
                    );
                    setActivities(activitiesResponse);

                    const processResponse = await ProcessService.getProcesses1(processMeasurementResponse?.processId);
                    setProcess(processResponse);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [id, withSpinner, handleError, filters]);

    const data = React.useMemo(() => {
        const transformActivity = (activity: Activity) => {
            const activityMeasurement = activityMeasurements?.result.find((elem) => elem.activityId === activity.id);

            if (activity.children?.length !== 0) {
                return {
                    id: activity.id,
                    name: activity.name,
                    baseline: activity.duration,
                    measurement: activityMeasurement,
                    description: activity.description,
                    children: activity.children?.length ? activity.children.map(transformActivity) : [],
                };
            }

            return {
                id: activity.id,
                name: activity.name,
                description: activity.description,
                mandatory: activity.mandatory,
                baseline: activity.duration,
                measurement: activityMeasurement,
                difference: activityMeasurement ? activity.duration - activityMeasurement.duration : undefined,
                children: activity.children?.length ? activity.children.map(transformActivity) : [],
            };
        };

        return activities?.result.map(transformActivity);
    }, [activities, activityMeasurements]);

    const allMandatoryActivitiesCompleted = React.useMemo(() => {
        if (!activities?.result || !activityMeasurements?.result || activityMeasurements?.result?.length === 0)
            return false;

        const getMandatoryLeafActivities = (activityList) =>
            activityList.reduce((leafActivities, activity) => {
                if (!activity.children || activity.children.length === 0) {
                    if (activity.mandatory) {
                        return [...leafActivities, activity];
                    }
                    return leafActivities;
                }
                return [...leafActivities, ...getMandatoryLeafActivities(activity.children)];
            }, []);

        const mandatoryLeafActivities = getMandatoryLeafActivities(activities.result);

        return mandatoryLeafActivities.every((activity) =>
            activityMeasurements?.result
                .filter((am) => am.duration != null)
                .some((measurement) => measurement.activityId === activity.id),
        );
    }, [activities, activityMeasurements]);

    return (
        <Container>
            <Paper elevation={0} sx={{ padding: 3, width: '100%', margin: 'auto' }} square>
                <Link href={`/processes/${process?.id}`} color="primary">
                    {process?.name}
                </Link>
            </Paper>

            <br />
            <TreeViewTableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={activities?.page?.totalElements}
                pageCount={activities?.page?.totalPages}
                rows={data}
                initial={initial}
                columns={columns}
                setFilters={setFilters}
            />
            <Button
                variant="contained"
                onClick={handleCompleteMeasurement}
                style={{ margin: '10px 0' }}
                startIcon={<AddIcon />}
                disabled={
                    (!allMandatoryActivitiesCompleted &&
                        processMeasurement?.type === ProcessMeasurementType.COMPLETE) ||
                    processMeasurement?.completed
                }
            >
                Комплетирај мерење
            </Button>
            <DurationOverlay
                onSubmit={() => {}}
                measurement={currentMeasurement}
                open={durationOverlay}
                handleClose={() => {
                    setDurationOverlay(false);
                    setCurrentMeasurement(undefined);
                }}
            />
        </Container>
    );
};

export default ActivityMeasurements;
