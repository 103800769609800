import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import { Tab } from '@mui/material';
import TabPanel from 'ui/components/tabs/TabPanel';

type TabsItem<T = {}> = {
    id: string;
    label: string;
    component: any;
    active: boolean;
    props?: T;
};

type TabsType<T = {}> = {
    [x: string]: TabsItem<T>;
};

type Props<T = {}> = {
    tabs: TabsType<T>;
};

const TabsComponent = ({ tabs }: Props) => {
    const [value, setValue] = React.useState(Object.keys(tabs).find((key) => tabs[key].active));

    const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }, []);

    return (
        <>
            <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, bgcolor: '#081627' }}>
                <Tabs value={value} textColor="inherit" onChange={handleChange}>
                    {Object.keys(tabs).map((key) => (
                        <Tab key={key} label={tabs[key].label} value={tabs[key].id} />
                    ))}
                </Tabs>
            </AppBar>
            {Object.keys(tabs).map((key) => {
                const { component: Component, props } = tabs[key];

                return (
                    <TabPanel key={tabs[key].id} value={value} index={tabs[key].id}>
                        <Component {...props} />
                    </TabPanel>
                );
            })}
        </>
    );
};

export default TabsComponent;
