import { object, string } from 'yup';
import { makeValidate, showErrorOnBlur, TextField } from 'mui-rff';
import React from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Form } from 'react-final-form';

const AddEmployee = ({ open, handleClose, onSubmit, employee }) => {
    const { state } = React.useContext(ApplicationContext);
    const {
        organization: { id },
    } = state;

    const schema = object({
        firstName: string().required(),
        lastName: string().required(),
        uid: string().required(),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Додади вработен</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={employee || { organizationId: id }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Име"
                                        name="firstName"
                                        showError={showErrorOnBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Презиме"
                                        name="lastName"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="outlined" fullWidth id="uid" label="Шифра" name="uid" />
                                </Grid>

                                <Grid item xs={12} />
                            </Grid>

                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddEmployee;
