import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import theme from 'ui/components/theme';
import { Bootstrapper } from 'ui/Bootstrapper';
import { ApplicationContextProvider } from 'ui/context/ApplicationContext';
import { AuthBootstrapper } from 'ui/AuthBootstrapper';
import { setLocale } from 'yup';
import { RoutesWrapper } from 'ui/RoutesWrapper';

function App() {
    setLocale({
        mixed: {
            required: 'Задолжително поле',
        },
        string: {
            email: 'Внесете валиден е-маил',
            length: 'Полето треба да има точно ${length} карактери',
        },
        number: {
            moreThan: 'Внесете вредност поголема од ${more}',
            lessThan: 'Внесете вредност помала од ${less}',
        },
    });

    return (
        <ApplicationContextProvider>
            <ThemeProvider theme={theme}>
                <AuthBootstrapper>
                    <Bootstrapper>
                        <RoutesWrapper />
                    </Bootstrapper>
                </AuthBootstrapper>
            </ThemeProvider>
        </ApplicationContextProvider>
    );
}

export default App;
