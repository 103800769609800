import { sharedReducer } from 'ui/context/shared/reducers';
import { Action, ApplicationState } from 'ui/context/types';
import { organizationsReducer } from 'ui/context/organizations/reducers';
import { userDetailsReducer } from 'ui/context/user/reducers';

export const rootReducer = (state: ApplicationState, action: Action): ApplicationState => {
    const { shared, organization, user } = state;

    return {
        shared: sharedReducer(shared, action),
        user: userDetailsReducer(user, action),
        organization: organizationsReducer(organization, action),
    };
};
