import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Button, MenuItem, Checkbox } from '@mui/material';
import { Autocomplete, AutocompleteData, makeValidate, Select } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { ProcessMeasurementType, ProcessRef } from 'ui/api/gen';
import { PROCESS_MEASUREMENT_TYPE } from 'ui/utils/enums';
import { object, string } from 'yup';

interface Props {
    open: boolean;
    onSubmit: any;
    processId?: string;
    processes: ProcessRef[];
    handleClose: () => void;
}

const AddProcessMeasurement = ({ open, processId, onSubmit, handleClose, processes = [] }: Props) => {
    const types = React.useMemo(
        () => [
            { id: ProcessMeasurementType.COMPLETE, name: PROCESS_MEASUREMENT_TYPE[ProcessMeasurementType.COMPLETE] },
            { id: ProcessMeasurementType.PARTIAL, name: PROCESS_MEASUREMENT_TYPE[ProcessMeasurementType.PARTIAL] },
        ],
        [],
    );

    const schema = object({
        processId: string().required(),
        type: string().required(),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" disableEscapeKeyDown>
            <DialogTitle>Додади мерење за процес</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{ processId }}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <br />
                            <Autocomplete
                                label="Избери процес"
                                name="processId"
                                freeSolo
                                options={processes}
                                required
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option: string | AutocompleteData) =>
                                    (option as AutocompleteData).name
                                }
                                disableCloseOnSelect
                                renderOption={(props, option: AutocompleteData, { selected }) =>
                                    option.inputValue ? (
                                        option.name
                                    ) : (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 5 }} checked={selected} />
                                            {option.name}
                                        </li>
                                    )
                                }
                                selectOnFocus
                                disabled={!!processId}
                                clearOnBlur
                                handleHomeEndKeys
                            />{' '}
                            <br />
                            <Select
                                name="type"
                                label="Селектирај тип на мерење"
                                required
                                formControlProps={{ margin: 'normal' }}
                            >
                                {types.map((type) => (
                                    <MenuItem value={type.id}>{type.name}</MenuItem>
                                ))}
                            </Select>
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddProcessMeasurement;
