import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'ui/App';
import 'ui/index.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const onSigninCallbackonSigninCallback = (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

export const userManager = new UserManager({
    // authority: 'http://localhost:8080/realms/process-manager/',
    authority: 'https://timeuse.insider.mk/keycloak/realms/process-manager/',
    client_id: 'process-manager-webapp',
    redirect_uri: `${window.location.origin}${window.location.pathname}`,
    post_logout_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: true, // this allows cross tab login/logout detection
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallbackonSigninCallback}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthProvider>,
);
