import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GlobalErrorPage = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
            }}
        >
            <Typography
                variant="h1"
                component="div"
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: '8rem',
                    fontWeight: 'bold',
                    mb: 1,
                }}
            >
                Грешка
            </Typography>

            <Typography
                variant="h4"
                component="div"
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: '2rem',
                    fontWeight: 'bold',
                }}
            >
                Ве молиме обидете се повторно
            </Typography>

            <Box
                sx={{
                    height: '33%',
                    width: '100%',
                    backgroundColor: theme.palette.primary.main,
                }}
            />
        </Box>
    );
};

export default GlobalErrorPage;
