import * as React from 'react';
import TabsComponent from 'ui/components/tabs/TabsComponent';
import { InvitationsTab } from 'ui/pages/users/invitations/InvitationsTab';
import UsersTab from 'ui/pages/users/users/UsersTab';

const TABS = {
    USERS: {
        id: 'USERS',
        label: 'Корисници',
        component: UsersTab,
        active: true,
    },
    INVITATIONS: {
        id: 'INVITATIONS',
        label: 'Покани',
        component: InvitationsTab,
        active: false,
    },
};

const UsersPage = () => <TabsComponent tabs={TABS} />;

export default UsersPage;
