import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ActionMenu = ({ params, actions, disabled = false }) => {
    const [action, setAction] = React.useState<null | HTMLElement>(null);

    const handleClick = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
        setAction(currentTarget);
    };

    const handleClose = React.useCallback(() => {
        setAction(null);
    }, []);

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={action} keepMounted open={!!action} onClose={handleClose}>
                {Object.keys(actions).map((key) => (
                    <MenuItem
                        key={key}
                        disabled={disabled}
                        onClick={() => {
                            handleClose();
                            return actions[key].callback(params);
                        }}
                    >
                        {actions[key].label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ActionMenu;
