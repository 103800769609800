import * as React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
    children: React.ReactNode;
    value?: string;
    index?: string;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
    <div role="tabpanel" hidden={value !== index} id={`panel-${index}`}>
        {value === index && (
            <Box p={1}>
                <Typography component="span">{children}</Typography>
            </Box>
        )}
    </div>
);

export default TabPanel;
