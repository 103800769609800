import React from 'react';
import { OrganizationRef, OrganizationService } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { useErrorHandler } from 'ui/utils/hooks';
import { setOrganization } from 'ui/context/organizations/actions';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { MenuItem, Select, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const WhiteSelect = styled(Select)(() => ({
    color: '#fff',
    fontSize: '1.5rem',
    '& .MuiSelect-icon': {
        color: '#fff',
    },
    '&:before': {
        borderColor: 'var(--galaxy-blue)',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderColor: 'var(--galaxy-blue)',
    },
}));

const OrganizationChooser = () => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    const [organizations, setOrganizations] = React.useState<OrganizationRef[]>([]);

    const {
        dispatch,
        state: { organization },
    } = React.useContext(ApplicationContext);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const response = await OrganizationService.getOrganizationsRef();
                setOrganizations(response);

                if (response?.length === 1) {
                    dispatch(setOrganization(response[0]));
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };

        initialize();
    }, [dispatch, withSpinner, handleError]); // Ensure effect runs when fetchOrganizations changes

    const handleChange = React.useCallback(
        (event) => {
            const { value } = event.target;
            const o = organizations.find((elem) => elem?.id === value);
            if (o) {
                dispatch(setOrganization(o));
            }
        },
        [dispatch, organizations],
    );

    if (organizations?.length === 0) {
        return null;
    }

    return (
        <div>
            {organizations?.length > 1 ? (
                <WhiteSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={organization?.id}
                    variant="standard"
                    sx={{ color: 'white' }}
                    label="Организација"
                    onChange={handleChange}
                >
                    {organizations.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                        </MenuItem>
                    ))}
                </WhiteSelect>
            ) : (
                <Typography variant="h5">
                    <strong> {organization?.name}</strong>
                </Typography>
            )}
        </div>
    );
};

export default OrganizationChooser;
