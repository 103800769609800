import { Box } from '@mui/material';
import React from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { initialFilters } from 'ui/utils/datagrid';
import {
    MaterialReactTable,
    MRT_ColumnFiltersState,
    MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';
import EmptyState from 'ui/components/svg/EmptyState';

type Props = {
    rows: any[] | undefined;
    columns: any[];
    pageIndex: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    setFilters: any;
    initial?: any;
    enableRowSelection?: boolean;
    setRowSelectionCallback?: any;
};

const TreeViewTableComponent = ({
    initial,
    rows = [],
    columns,
    pageSize,
    pageCount,
    rowCount = 0,
    pageIndex,
    setFilters,
    enableRowSelection = false,
    setRowSelectionCallback,
}: Props) => {
    const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
    const [pagination, setPagination] = React.useState({
        pageIndex,
        pageSize,
    });
    const [rowSelection, setRowSelection] = React.useState({});

    const { state } = React.useContext(ApplicationContext);
    const {
        shared: { spinner },
    } = state;

    const onSortModelChange = React.useCallback(
        (model: any) => {
            const elem = model[0];

            if (elem) {
                const order = elem.desc ? 'desc' : 'asc';
                setFilters((prevFilters) => ({ ...prevFilters, sort: `${elem.id},${order}` }));
            } else {
                setFilters(initial || initialFilters);
            }
        },
        [setFilters, initial],
    );

    const onPaginationModelChange = React.useCallback(
        (model: any) => {
            setFilters((prevFilters) => ({ ...prevFilters, ...model }));
        },
        [setFilters],
    );

    useDeepCompareEffect(() => {
        onSortModelChange(sorting);
    }, [onSortModelChange, sorting]);

    useDeepCompareEffect(() => {
        setFilters((prevFilters) => ({ ...prevFilters, columnFilters }));
    }, [columnFilters]);

    useDeepCompareEffect(() => {
        onPaginationModelChange(pagination);
    }, [pagination, pagination, onPaginationModelChange]);

    useDeepCompareEffect(() => {
        if (setRowSelectionCallback) {
            setRowSelectionCallback(rowSelection);
        }
    }, [rowSelection]);

    const getBackgroundColor = (depth: number): string => {
        const baseColor = '#ffffff'; // White for the highest depth
        const darkerColor = '#f5f5f5'; // Light gray for intermediate depths

        if (depth === 0) {
            return baseColor;
        }
        if (depth === 1) {
            return darkerColor;
        }
        return '#e0e0e0';
    };

    const table = useMaterialReactTable({
        columns,
        data: rows,
        manualSorting: true,
        manualFiltering: true,
        manualPagination: true,
        enableRowSelection,
        onRowSelectionChange: setRowSelection,
        getRowId: (row) => row.id.toString(),
        enableGlobalFilter: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        enableExpandAll: true,
        enableExpanding: true,
        layoutMode: 'grid-no-grow',
        positionExpandColumn: 'first',
        filterFromLeafRows: true,
        enableColumnFilterModes: false,
        enableFilterMatchHighlighting: false,
        enableColumnResizing: false,
        muiTablePaperProps: {
            elevation: 0,
        },
        rowCount,
        pageCount,
        getSubRows: (row) => row.children,
        state: { rowSelection, sorting, showProgressBars: !!spinner, columnFilters, pagination },
        initialState: {
            expanded: true,
            density: 'compact',
            showColumnFilters: true,
            sorting: [{ id: 'createdAt', desc: false }],
        },
        muiLinearProgressProps: ({ isTopToolbar }) => ({
            color: 'secondary',
            sx: { display: isTopToolbar ? 'none' : 'block' }, // only show top toolbar progress bar
        }),
        enableStickyHeader: true,
        muiTableHeadRowProps: () => ({
            sx: {
                backgroundColor: '#dde7ea',
            },
        }),
        localization: {
            selectedCountOfRowCountRowsSelected: 'Селектирани редови',
            clearSelection: 'Избриши селекција',
            rowsPerPage: 'Редови по страница',
            noRecordsToDisplay: 'Нема записи',
            sortByColumnAsc: 'Сортирај',
            sortByColumnDesc: 'Сортирај',
            sortedByColumnAsc: 'Сортирај',
            hideAll: 'Сокриј ги сите',
            showAll: 'Прикажи ги сите',
            sortedByColumnDesc: 'Сортирај',
            filterByColumn: 'Филтрирај',
            showHideColumns: 'Прикажи/Сокриј колони',
            showHideFilters: 'Прикажи/Сокриј филтри',
            toggleFullScreen: 'Прикажи на целосен екран',
        },

        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 70,
                grow: false,
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: getBackgroundColor(row.depth),
            },
        }),
        renderEmptyRowsFallback: EmptyState,
    });

    return (
        <Box>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TreeViewTableComponent;
