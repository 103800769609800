import { Switches } from 'mui-rff';
import React from 'react';
import { useForm } from 'react-final-form';

const ActivitySwitch = ({ selectedItem }) => {
    const formApi = useForm();

    return (
        <Switches
            label="Задолжително"
            name="mandatory"
            required
            data={{ label: '', value: true }}
            onClick={(event) => {
                // @ts-ignore
                if (!event?.target?.checked && selectedItem?.children?.length !== 0) {
                    formApi.change('duration', 0);
                }
            }}
        />
    );
};

export default ActivitySwitch;
