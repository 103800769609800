/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activity } from '../models/Activity';
import type { PageableActivities } from '../models/PageableActivities';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ActivityService {
    /**
     * Get all activities of process
     * Get all activities of process
     * @param id Process id
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param name name of the process
     * @param mandatory
     * @returns PageableActivities Get all activities of an process
     * @throws ApiError
     */
    public static getProcessesActivities(
        id: string,
        size?: number,
        page?: number,
        sort?: string,
        name?: string,
        mandatory?: boolean,
    ): CancelablePromise<PageableActivities> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{id}/activities',
            path: {
                'id': id,
            },
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'name': name,
                'mandatory': mandatory,
            },
        });
    }
    /**
     * Create an activity for a process
     * Create an activity for a process
     * @param id Process id to retrieve
     * @param requestBody
     * @returns Activity Successful operation
     * @throws ApiError
     */
    public static postProcessesActivities(
        id: string,
        requestBody: Activity,
    ): CancelablePromise<Activity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/processes/{id}/activities',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete activity by id
     * Delete activity by id
     * @param id Activity id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteProcessesActivities(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/processes/activities/{id}',
            path: {
                'id': id,
            },
        });
    }
}
