import { styled } from '@mui/material';
import * as React from 'react';
import Tile from 'ui/pages/homepage/Tile';
import { routes } from 'ui/routing';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StraightenIcon from '@mui/icons-material/Straighten';
import SettingIcon from '@mui/icons-material/Settings';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const ParentContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    width: 100%;
`;

const Container = styled('div')`
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

const HomePage = () => {
    const { state } = React.useContext(ApplicationContext);
    const { user } = state;

    const categories = [
        {
            id: 'Мој Профил',
            path: routes.USERS_DETAILS.path,
            icon: BadgeIcon,
            roles: routes.USERS_DETAILS.roles,
        },
        {
            id: 'Корисници',
            path: routes.USERS.path,
            icon: PersonIcon,
            roles: routes.USERS.roles,
        },
        {
            id: 'Организации',
            path: routes.ORGANIZATIONS.path,
            icon: BusinessIcon,
            roles: routes.ORGANIZATIONS.roles,
        },
        {
            id: 'Процеси',
            path: routes.PROCESSES.path,
            icon: AccountTreeIcon,
            roles: routes.PROCESSES.roles,
        },
        {
            id: 'Мерења',
            path: routes.MEASUREMENT.path,
            icon: StraightenIcon,
            roles: routes.MEASUREMENT.roles,
        },
        {
            id: 'Конфигурација',
            path: routes.CONFIGURATION.path,
            icon: SettingIcon,
            roles: routes.CONFIGURATION.roles,
        },
    ];

    return (
        <ParentContainer>
            <Container>
                {categories
                    .filter((key) => {
                        const { roles } = key;
                        // Check if the user has at least one role required by the route
                        return roles.some((role) => role === user.role);
                    })
                    .map(({ path, icon, id }) => (
                        <Tile key={path} icon={icon} title={id} route={path} />
                    ))}
            </Container>
        </ParentContainer>
    );
};

export default HomePage;
