import React, { ReactNode } from 'react';
import { rootReducer } from 'ui/context/rootReducer';
import { initialState } from 'ui/context/initialState';
import { ApplicationContextType } from 'ui/context/types';

const initialValues = {
  state: initialState,
  dispatch: () => {},
};

export const ApplicationContext: React.Context<ApplicationContextType> =
  React.createContext<ApplicationContextType>(initialValues);

type Props = {
  children: ReactNode;
};

export function ApplicationContextProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(rootReducer, initialState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationContext.Provider>
  );
}
