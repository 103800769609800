import { BackendUser } from 'ui/api/gen';

export const GET_USER_DETAILS = 'APP@USER_DETAILS.GET';
export const SET_USER_DETAILS = 'APP@USER_DETAILS.SET';

export const getUserDetails = () => ({
  type: GET_USER_DETAILS,
});

export const setUserDetails = (user: BackendUser) => ({
  type: SET_USER_DETAILS,
  user,
});
