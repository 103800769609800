import React, { ReactNode } from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { BackendUser, MeService, OrganizationService, type PageableOrganizations } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { setUserDetails } from 'ui/context/user/actions';
import { setOrganization } from 'ui/context/organizations/actions';
import ErrorOverlay from 'ui/components/erroroverlay/ErrorOverlay';
import { hideError } from 'ui/context/shared/actions';
import { useNavigate } from 'react-router-dom';
import GlobalError from 'ui/pages/common/GlobalError';
import { useErrorHandler } from 'ui/utils/hooks';

type Props = {
    children: ReactNode;
};

export function Bootstrapper({ children }: Props) {
    const [initialized, setInitialized] = React.useState(false);
    const navigate = useNavigate();
    const {
        dispatch,
        state: {
            shared: { error, spinner },
        },
    } = React.useContext(ApplicationContext);

    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const user: BackendUser = await MeService.meInitialize();
                dispatch(setUserDetails(user));

                const items: PageableOrganizations = await OrganizationService.getOrganizations();
                dispatch(setOrganization(items.result?.[0]));
                setInitialized(true);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
                navigate('/error');
            }
        };
        withSpinner(initialize);
    }, [withSpinner, dispatch, handleError]);

    const handleClose = React.useCallback(() => {
        dispatch(hideError());
    }, [dispatch]);

    return (
        <>
            {initialized && children}
            {!initialized && error && <GlobalError />}
            {initialized && <ErrorOverlay open={error} onClose={handleClose} />}
        </>
    );
}
