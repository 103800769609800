import { Action, ActionMappings } from 'ui/context/types';

export function reducerWithActionMappings<S, A extends Action>(
  mappings: ActionMappings<S, A>,
  initialState: S,
) {
  return function configureReducer(state: S = initialState, action: A): S {
    const reducer = mappings[action.type];
    if (reducer) {
      return reducer(state, action);
    }

    return state;
  };
}
