import React, { useEffect, useRef } from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { showError } from 'ui//context/shared/actions';

export const useIsMounted = () => {
    const isMounted = useRef(true);

    useEffect(
        () =>
            // Component is mounted
            () => {
                // Component is unmounted
                isMounted.current = false;
            },
        [],
    );

    return isMounted.current;
};

export const useErrorHandler = () => {
    const { dispatch } = React.useContext(ApplicationContext);

    return React.useCallback(() => {
        dispatch(showError());
    }, [dispatch]);
};
