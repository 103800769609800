import * as React from 'react';
import { MemberService, OrganizationRef, OrganizationService, PageableMembers, Role, Status } from 'ui/api/gen';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useSpinner } from 'ui/hooks/spinner';
import { ROLE_LABEL, STATUS_LABEL } from 'ui/utils/enums';
import { useErrorHandler, useIsMounted } from 'ui/utils/hooks';
import { initialFilters } from 'ui/utils/datagrid';
import TableComponent from 'ui/components/grid/TableComponent';
import useDeepCompareEffect from 'use-deep-compare-effect';

const UsersTab = () => {
    const [filters, setFilters] = React.useState(initialFilters);
    const [pageable, setPageable] = React.useState<PageableMembers>();
    const [organizations, setOrganizations] = React.useState<OrganizationRef[]>([]);

    const isMounted = useIsMounted();
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const callback = async () => {
                try {
                    await MemberService.deleteMembers(id);

                    const { pageSize, pageIndex, sort } = filters;
                    const response: PageableMembers = await MemberService.getMembers(pageSize, pageIndex, sort);

                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [withSpinner, isMounted, filters, handleError],
    );

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, organizationId, firstName, lastName, email, role }) => ({
                id,
                firstName,
                lastName,
                email,
                organizationId,
                role,
            })),
        [pageable],
    );

    const actions = React.useMemo(
        () => ({
            DELETE: { label: 'Избриши', callback: handleDelete },
        }),
        [handleDelete],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'organizationId',
                header: 'Организација',
                filterVariant: 'autocomplete',
                muiFilterAutocompleteProps: {
                    options: organizations?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
                },

                flex: 2,
                accessorFn: ({ organizationId }) => organizations?.find((item) => item.id === organizationId)?.name,
            },
            {
                accessorKey: 'firstName',
                header: 'Име',
                flex: 1,
            },
            { accessorKey: 'lastName', header: 'Презиме', flex: 1 },

            {
                accessorKey: 'role',
                header: 'Улога',
                accessorFn: ({ role }) => ROLE_LABEL[role],
                flex: 1,
                filterVariant: 'select',
                filterSelectOptions: [
                    { value: Role.PM_OPERATOR, label: ROLE_LABEL[Role.PM_OPERATOR] },
                    { value: Role.PM_MANAGER, label: ROLE_LABEL[Role.PM_MANAGER] },
                    { value: Role.PM_CLUSTER, label: ROLE_LABEL[Role.PM_CLUSTER] },
                    { value: Role.PM_ADMIN, label: ROLE_LABEL[Role.PM_ADMIN] },
                ],
            },
            {
                accessorKey: 'email',
                header: 'Е-мејл',
                flex: 1,
            },
            {
                accessorKey: 'actions',
                header: '',
                size: 1,
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions, organizations],
    );

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const organizationsRef = await OrganizationService.getOrganizationsRef();

                if (isMounted) {
                    setOrganizations(organizationsRef);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, isMounted, handleError]);

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const response: PageableMembers = await MemberService.getMembers(
                    pageSize,
                    pageIndex,
                    sort,
                    columnFilters.find((i) => i.id === 'organizationId')?.value,
                    columnFilters.find((i) => i.id === 'email')?.value,
                    columnFilters.find((i) => i.id === 'role')?.value,
                    columnFilters.find((i) => i.id === 'firstName')?.value,
                    columnFilters.find((i) => i.id === 'lastName')?.value,
                );
                setPageable(response);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, filters, handleError]);

    return (
        <div>
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={pageable?.page?.totalElements}
                pageCount={pageable?.page?.totalPages}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
        </div>
    );
};
export default UsersTab;
