import * as React from 'react';
import { Activity, ActivityService, PageableActivities, Role, Status } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { Chip, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { secondsToHMS } from 'ui/utils/date';
import AddActivity from 'ui/pages/processes/AddActivity';
import { MANDATORY } from 'ui/utils/enums';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useErrorHandler } from 'ui/utils/hooks';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import { ColumnFilter } from 'ui/utils/datagrid';
import TreeViewTableComponent from 'ui/components/grid/TreeViewTableComponent';
import styled from '@emotion/styled';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const Text = styled('span')`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
    min-height: 50px;
`;

const initial = {
    pageIndex: 0,
    pageSize: 10,
    sort: 'createdAt,desc',
    columnFilters: [] as ColumnFilter[],
};

const Activities = ({ process }) => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [activities, setActivities] = React.useState<PageableActivities>();
    const [root, setRoot] = React.useState();
    const [selectedItem, setSelectedItem] = React.useState<Activity | undefined>();
    const [show, setShow] = React.useState(false);
    const [filters, setFilters] = React.useState(initial);

    const { state } = React.useContext(ApplicationContext);

    const { user } = state;

    const handleUpdate = React.useCallback((row) => {
        setSelectedItem(row.original);
        setRoot(row.original.parentId);
        setShow(true);
    }, []);

    const handleDelete = React.useCallback(
        (row) => {
            const update1 = async () => {
                try {
                    await ActivityService.deleteProcessesActivities(row?.original?.id);

                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const pageableActivities = await ActivityService.getProcessesActivities(
                        process?.id,
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'name')?.value,
                        columnFilters.find((i) => i.id === 'mandatory')?.value,
                    );
                    setActivities(pageableActivities);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(update1);
        },
        [filters, process?.id, withSpinner, handleError],
    );

    const handleAddSubLevel = React.useCallback((row) => {
        setRoot(row.original);
        setShow(true);
    }, []);

    const actions = React.useMemo(
        () => ({
            ADD: { label: 'Додани подниво', callback: handleAddSubLevel },
            UPDATE: { label: 'Ажурирај', callback: handleUpdate },
            DELETE: { label: 'Избриши', callback: handleDelete },
        }),
        [handleUpdate, handleDelete, handleAddSubLevel],
    );

    const resetState = React.useCallback(() => {
        setSelectedItem(undefined);
        setRoot(undefined);
        setShow(false);
    }, []);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                grow: 20,
                header: 'Фаза',
                enableColumnFilter: false,
                enableSorting: false,
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
                Cell: ({ cell, row }) => (
                    <div style={{ paddingLeft: `${row.depth * 25}px` }}>
                        {cell.getValue()}
                        {row.original.description && (
                            <Tooltip title={row.original.description}>
                                <IconButton color="secondary">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                ),
            },
            {
                grow: 1,
                accessorKey: 'duration',
                header: 'Очекувано времетраење',
                enableColumnFilter: false,
                enableSorting: false,
                accessorFn: ({ duration }) => secondsToHMS(duration),
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
            },
            {
                grow: 1,
                accessorKey: 'average',
                header: 'Просечно времетраење',
                type: 'number',
                enableColumnFilter: false,
                enableSorting: false,
                accessorFn: ({ average }) => secondsToHMS(average),
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
            },
            {
                grow: 1,
                accessorKey: 'mandatory',
                header: 'Статус',
                filterVariant: 'checkbox',
                enableColumnFilter: false,
                enableSorting: false,
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
                Cell: ({ row }) => {
                    const label = MANDATORY[row.original.mandatory];
                    const color = row.original.mandatory ? 'success' : undefined;

                    if (row.original.children?.length !== 0) {
                        return null;
                    }

                    return <Chip color={color} label={label} />;
                },
            },
            {
                size: 1,
                id: 'actions',
                header: '',
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ row }) => {
                    const filtered = Object.fromEntries(
                        Object.entries(actions).filter(([key]) => {
                            if (key === 'ADD') {
                                return row.depth !== 2;
                            }
                            return true;
                        }),
                    );

                    if (user?.role && ![Role.PM_MANAGER, Role.PM_ADMIN].includes(user.role)) {
                        return null;
                    }

                    return <ActionMenu params={row} actions={filtered} disabled={process?.status === Status.ACTIVE} />;
                },
            },
        ],
        [actions, process, user],
    );

    const handleSubmit = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    await ActivityService.postProcessesActivities(process?.id, values);
                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const activitiesResponse = await ActivityService.getProcessesActivities(
                        process?.id,
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'name')?.value,
                        columnFilters.find((i) => i.id === 'mandatory')?.value,
                    );
                    setActivities(activitiesResponse);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    resetState();
                }
            };
            withSpinner(save);
        },
        [process?.id, withSpinner, resetState, handleError, filters],
    );

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (process?.id) {
                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const pageableActivities = await ActivityService.getProcessesActivities(
                        process?.id,
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'name')?.value,
                        columnFilters.find((i) => i.id === 'mandatory')?.value,
                    );
                    setActivities(pageableActivities);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [process?.id, withSpinner, handleError, filters]);

    const data = React.useMemo(() => {
        const transformActivity = (activity: Activity) => {
            if (activity.children?.length !== 0) {
                return {
                    id: activity.id,
                    name: activity.name,
                    duration: activity.duration,
                    average: activity.avgDuration,
                    mandatory: activity.mandatory,
                    description: activity.description,
                    parentId: activity.parentId,
                    children: activity.children?.length ? activity.children.map(transformActivity) : [],
                };
            }

            return {
                id: activity.id,
                name: activity.name,
                description: activity.description,
                duration: activity.duration,
                average: activity.avgDuration,
                mandatory: activity.mandatory,
                parentId: activity.parentId,
                children: activity.children?.length ? activity.children.map(transformActivity) : [],
            };
        };

        return activities?.result.map(transformActivity);
    }, [activities]);

    return (
        <>
            <AddActivity
                open={show}
                onClose={resetState}
                onSubmit={handleSubmit}
                selectedItem={selectedItem}
                root={root}
            />

            {process?.status !== Status.ACTIVE && (
                <RestrictedAddButton
                    onClick={() => setShow(true)}
                    title="Додади фаза"
                    roles={[Role.PM_MANAGER, Role.PM_ADMIN]}
                />
            )}
            <TreeViewTableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                pageCount={activities?.page?.totalPages}
                rowCount={activities?.page?.totalElements}
                rows={data}
                columns={columns}
                initial={initial}
                setFilters={setFilters}
            />
        </>
    );
};

export default Activities;
