import React from 'react';
import ProcessConfig from 'ui/pages/processes/ProcessConfig';
import { useParams } from 'react-router-dom';
import { useSpinner } from 'ui/hooks/spinner';
import { Process, ProcessService } from 'ui/api/gen';
import ProcessMeasurementsPage from 'ui/pages/processmeasurements/ProcessMeasurementsPage';
import { useErrorHandler } from 'ui/utils/hooks';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import { Tab } from '@mui/material';
import TabPanel from 'ui/components/tabs/TabPanel';
import ProcessDetails from 'ui/pages/processes/ProcessDetails';

const tabs = {
    PROCESS_CONFIG: {
        id: 'PROCESS_CONFIG',
        label: 'Детали',
        component: ProcessConfig,
        active: true,
    },
    PROCESS_MEASUREMENTS: {
        id: 'PROCESS_MEASUREMENTS',
        label: 'Мерења',
        component: ProcessMeasurementsPage,
        active: false,
    },
};

const ProcessTabs = () => {
    const { id } = useParams();
    const [value, setValue] = React.useState(Object.keys(tabs).find((key) => tabs[key].active));

    const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }, []);

    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    const [current, setCurrent] = React.useState<Process | undefined>();
    const [forceUpdate, setForceUpdate] = React.useState(false);

    const toggleForceUpdate = React.useCallback(() => {
        setForceUpdate((v) => !v);
    }, []);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (id) {
                    const result = await ProcessService.getProcesses1(id);
                    setCurrent(result);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [id, withSpinner, handleError, forceUpdate]);

    return (
        <>
            <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, bgcolor: '#081627' }}>
                <Tabs value={value} textColor="inherit" onChange={handleChange}>
                    {Object.keys(tabs).map((key) => (
                        <Tab key={key} label={tabs[key].label} value={tabs[key].id} />
                    ))}
                </Tabs>
            </AppBar>
            <ProcessDetails process={current} forceUpdate={toggleForceUpdate} />
            {Object.keys(tabs).map((key) => {
                const { component: Component } = tabs[key];

                return (
                    <TabPanel key={tabs[key].id} value={value} index={tabs[key].id}>
                        <Component process={current} />
                    </TabPanel>
                );
            })}
        </>
    );
};

export default ProcessTabs;
