/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityMeasurement } from '../models/ActivityMeasurement';
import type { ActivityMeasurementPatch } from '../models/ActivityMeasurementPatch';
import type { PageableActivityMeasurements } from '../models/PageableActivityMeasurements';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ActivityMeasurementService {
    /**
     * Create a measurement for a process
     * Create a measurement for a process
     * @param processMeasurementId Id of the process that is measured.
     * @param activityId Id of the process that is measured.
     * @param requestBody
     * @returns ActivityMeasurement Successful operation
     * @throws ApiError
     */
    public static postProcessesMeasurementsActivitiesMeasurements(
        processMeasurementId: string,
        activityId: string,
        requestBody: ActivityMeasurement,
    ): CancelablePromise<ActivityMeasurement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/processes/measurements/{processMeasurementId}/activities/{activityId}/measurements',
            path: {
                'processMeasurementId': processMeasurementId,
                'activityId': activityId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all measurements of a process
     * Get all measurements of a process
     * @param processMeasurementId Id of the process that is measured.
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @returns PageableActivityMeasurements Get all measurements of an activity
     * @throws ApiError
     */
    public static getProcessesMeasurementsActivitiesMeasurements(
        processMeasurementId: string,
        size?: number,
        page?: number,
        sort?: string,
    ): CancelablePromise<PageableActivityMeasurements> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/measurements/{processMeasurementId}/activities/measurements',
            path: {
                'processMeasurementId': processMeasurementId,
            },
            query: {
                'size': size,
                'page': page,
                'sort': sort,
            },
        });
    }
    /**
     * Update single measurements of an activity
     * Update single measurements of an activity
     * @param id Id of the activity measurement
     * @param requestBody The attribute to be updated
     * @returns ActivityMeasurement Get all measurements of a process
     * @throws ApiError
     */
    public static patchProcessesMeasurementsActivitiesMeasurements(
        id: string,
        requestBody: ActivityMeasurementPatch,
    ): CancelablePromise<ActivityMeasurement> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/processes/measurements/activities/measurements/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete single measurements of an activity
     * Delete single measurements of an activity
     * @param id Id of the activity measurement
     * @returns void
     * @throws ApiError
     */
    public static deleteProcessesMeasurementsActivitiesMeasurements(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/processes/measurements/activities/measurements/{id}',
            path: {
                'id': id,
            },
        });
    }
}
