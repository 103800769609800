/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Role {
    PM_ADMIN = 'PM_ADMIN',
    PM_CLUSTER = 'PM_CLUSTER',
    PM_MANAGER = 'PM_MANAGER',
    PM_OPERATOR = 'PM_OPERATOR',
}
