/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyProcess } from '../models/CopyProcess';
import type { PageableProcesses } from '../models/PageableProcesses';
import type { Process } from '../models/Process';
import type { ProcessPatch } from '../models/ProcessPatch';
import type { ProcessRef } from '../models/ProcessRef';
import type { Status } from '../models/Status';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessService {
    /**
     * Get all processes of organization
     * Get all processes of organization
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param organizationId id of the organization
     * @param name name of the process
     * @param status
     * @param product
     * @returns PageableProcesses Get all processes of an organization
     * @throws ApiError
     */
    public static getProcesses(
        size?: number,
        page?: number,
        sort?: string,
        organizationId?: string,
        name?: string,
        status?: Status,
        product?: string,
    ): CancelablePromise<PageableProcesses> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'organizationId': organizationId,
                'name': name,
                'status': status,
                'product': product,
            },
        });
    }
    /**
     * Update an process for a organization
     * Update an process for a organization
     * @param requestBody
     * @returns Process Successful operation
     * @throws ApiError
     */
    public static putProcesses(
        requestBody: Process,
    ): CancelablePromise<Process> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/processes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create an process for a organization
     * Create an process for a organization
     * @param requestBody
     * @returns Process Successful operation
     * @throws ApiError
     */
    public static postProcesses(
        requestBody: Process,
    ): CancelablePromise<Process> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/processes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all processes of organization
     * Get all processes of organization
     * @param organizationId
     * @param status
     * @returns ProcessRef Get all processes of an organization
     * @throws ApiError
     */
    public static getProcessesRef(
        organizationId?: string,
        status?: Status,
    ): CancelablePromise<Array<ProcessRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/ref',
            query: {
                'organizationId': organizationId,
                'status': status,
            },
        });
    }
    /**
     * Copy process
     * Copy process
     * @param id Id of the process
     * @param requestBody List of activities which need to be copied from the original process
     * @returns Process Copied Process
     * @throws ApiError
     */
    public static postProcessesCopy(
        id: string,
        requestBody: CopyProcess,
    ): CancelablePromise<Process> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/processes/{id}/copy',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update single process
     * Update single process
     * @param id Id of the measurement
     * @param requestBody The attribute to be updated
     * @returns Process Updated Process
     * @throws ApiError
     */
    public static patchProcesses(
        id: string,
        requestBody: ProcessPatch,
    ): CancelablePromise<Process> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/processes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an process
     * Delete an process
     * @param id Process id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteProcesses(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/processes/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get a process by id
     * Get a process by id
     * @param id Process id to retrieve
     * @returns Process Successful operation
     * @throws ApiError
     */
    public static getProcesses1(
        id: string,
    ): CancelablePromise<Process> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{id}',
            path: {
                'id': id,
            },
        });
    }
}
