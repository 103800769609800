/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessStatistic } from '../models/ProcessStatistic';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessStatisticsService {
    /**
     * Get process statistics
     * Get process statistics
     * @param organizationId Id of the organization
     * @param processIds Ids of the processes
     * @returns ProcessStatistic Successful operation
     * @throws ApiError
     */
    public static getProcessStatistics(
        organizationId?: string,
        processIds?: Array<string>,
    ): CancelablePromise<Array<ProcessStatistic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-statistics',
            query: {
                'organizationId': organizationId,
                'processIds': processIds,
            },
        });
    }
}
