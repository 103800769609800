import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StraightenIcon from '@mui/icons-material/Straighten';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';
import SettingIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'ui/routing';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import logo from 'ui/components/assets/logo.png';
import { styled } from '@mui/material';
import { ReactNode } from 'react';

type Child = {
    id: string;
    path: string;
    icon: ReactNode;
    roles: string[];
};

type Category = {
    id: string;
    children: Child[];
};

const categories: Category[] = [
    {
        id: 'Администрација',
        children: [
            {
                id: 'Мој Профил',
                path: routes.USERS_DETAILS.path,
                icon: <BadgeIcon />,
                roles: routes.USERS_DETAILS.roles,
            },
            {
                id: 'Корисници',
                path: routes.USERS.path,
                icon: <PersonIcon />,
                roles: routes.USERS.roles,
            },
            {
                id: 'Организации',
                path: routes.ORGANIZATIONS.path,
                icon: <BusinessIcon />,
                roles: routes.ORGANIZATIONS.roles,
            },
        ],
    },
    {
        id: 'Процеси',
        children: [
            {
                id: 'Процеси',
                path: routes.PROCESSES.path,
                icon: <AccountTreeIcon />,
                roles: routes.PROCESSES.roles,
            },
            {
                id: 'Мерења',
                path: routes.MEASUREMENT.path,
                icon: <StraightenIcon />,
                roles: routes.MEASUREMENT.roles,
            },
        ],
    },
    {
        id: 'Конфигурација',
        children: [
            {
                id: 'Конфигурација',
                path: routes.CONFIGURATION.path,
                icon: <SettingIcon />,
                roles: routes.CONFIGURATION.roles,
            },
        ],
    },
];

const StyledContainer = styled('div')`
    background-image: url(${logo});
    width: 185px;
    height: 90px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
`;

export default function Navigator(props: DrawerProps) {
    const { ...other } = props;

    const { pathname } = useLocation();
    const { state } = React.useContext(ApplicationContext);
    const { user } = state;

    const filteredCategories = categories
        .map((cat) => {
            const filteredChildren = cat.children.filter(({ roles }) => roles.includes(user.role));

            return filteredChildren.length > 0 ? { ...cat, children: filteredChildren } : null;
        })
        .filter((cat) => cat !== null);

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <StyledContainer />
                <ListItem sx={{ color: '#fff' }}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemButton sx={{ color: '#fff' }} component={Link} to={routes.DASHBOARD.path}>
                        Почетна
                    </ListItemButton>
                </ListItem>

                {filteredCategories.map((category: any) => {
                    const { id, children } = category;

                    return (
                        <Box key={id} sx={{ bgcolor: '#101F33' }}>
                            <ListItem sx={{ py: 2, px: 3 }}>
                                <ListItemText
                                    sx={{ color: '#fff' }}
                                    primaryTypographyProps={{ fontSize: '16px!important', fontWeight: 'bold' }}
                                >
                                    {id}
                                </ListItemText>
                            </ListItem>

                            {children.map(({ id: childId, icon, path, roles }) => {
                                if (!roles.find((role) => role === user.role)) {
                                    return null;
                                }

                                return (
                                    <ListItem disablePadding key={childId}>
                                        <ListItemButton
                                            selected={pathname.startsWith(path)}
                                            sx={{ color: '#fff' }}
                                            component={Link}
                                            to={path}
                                        >
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText>{childId}</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                            <Divider sx={{ mt: 2 }} />
                        </Box>
                    );
                })}
            </List>
        </Drawer>
    );
}
