import * as React from 'react';
import { Alert, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';

type Props = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

const ConfirmationDialog = ({ onConfirm, onCancel, open }: Props) => (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
        <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning">
            <Typography variant="body1">Напомена</Typography>
        </Alert>
        <Divider />
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Доколку го активирате процесот нема да можете да правите промени на постоечкиот. Дали сакате да
                продолжите?
            </DialogContentText>
            <br />
            <Button onClick={onConfirm} color="success" variant="contained">
                Да
            </Button>
            <span>&nbsp;</span>
            <Button onClick={onCancel} color="inherit" variant="contained">
                Не
            </Button>
        </DialogContent>
    </Dialog>
);

export default ConfirmationDialog;
