export interface ColumnFilter {
    id: string;
    value: any;
}

interface InitialFilters {
    pageIndex: number;
    pageSize: number;
    sort: string;
    columnFilters: ColumnFilter[];
}

export const initialFilters: InitialFilters = {
    pageIndex: 0,
    pageSize: 10,
    sort: 'createdAt,desc',
    columnFilters: [],
};
