import { Box, CssBaseline } from '@mui/material';
import React from 'react';

import Header from 'ui/layout/Header';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from 'ui/layout/Navigator';
import theme from 'ui/components/theme';
import { Outlet } from 'react-router-dom';

export function Container() {
    const [open, setOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const toggleDrawer = React.useCallback(() => {
        setOpen((value) => !value);
    }, []);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            <Box component="nav" sx={{ width: { sm: 250 }, flexShrink: { sm: 0 } }}>
                {isSmUp ? null : (
                    <Navigator
                        PaperProps={{ style: { width: 250 } }}
                        variant="temporary"
                        open={open}
                        onClose={toggleDrawer}
                    />
                )}
                <Navigator PaperProps={{ style: { width: 250 } }} sx={{ display: { sm: 'block', xs: 'none' } }} />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Header onDrawerToggle={toggleDrawer} />
                <Box component="main" sx={{ flex: 1, bgcolor: '#eaeff1' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}
