import React from 'react';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { makeValidate, Select, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { OrganizationRef, Role } from 'ui/api/gen';
import { object, string } from 'yup';
import { ROLE_LABEL } from 'ui/utils/enums';

type Props = {
    open: boolean;
    onSubmit: (values: any) => void;
    handleClose: () => void;
    organizations: Array<OrganizationRef>;
};

const InviteUser = ({ open, organizations, handleClose, onSubmit }: Props) => {
    const roles = React.useMemo(
        () => [
            { id: Role.PM_MANAGER, name: ROLE_LABEL[Role.PM_MANAGER] },
            { id: Role.PM_OPERATOR, name: ROLE_LABEL[Role.PM_OPERATOR] },
        ],
        [],
    );

    const organizationModel = React.useMemo(
        () =>
            organizations.map((item) => ({
                id: item.id,
                name: item.name,
            })),
        [organizations],
    );

    const schema = object({
        email: string().email().required(),
        role: string().required(),
        organizationId: string().required(),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Додади корисник</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        name="role"
                                        required
                                        label="Селектирај ролја"
                                        formControlProps={{ margin: 'normal' }}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        name="organizationId"
                                        required
                                        label="Селектирај организација"
                                        formControlProps={{ margin: 'normal' }}
                                    >
                                        {organizationModel.map((organization) => (
                                            <MenuItem value={organization.id}>{organization.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Е-Маил"
                                        style={{ marginBottom: '15px', borderRadius: 0 }}
                                        name="email"
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default InviteUser;
