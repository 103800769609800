import { SharedState } from 'ui/context/shared/types';
import { reducerWithActionMappings } from 'ui/context/reducers';
import { HIDE_ERROR, HIDE_SPINNER, SHOW_ERROR, SHOW_SPINNER } from 'ui/context/shared/actions';
import { Action } from 'ui/context/types';
import { initialState } from 'ui/context/initialState';

const showSpinner = (state: SharedState, action: Action): SharedState => ({
    ...state,
    spinner: state.spinner + 1,
});

const hideSpinner = (state: SharedState, action: Action): SharedState => ({
    ...state,
    spinner: state.spinner - 1,
});

const showError = (state: SharedState, action: Action): SharedState => ({
    ...state,
    error: true,
});

const hideError = (state: SharedState, action: Action): SharedState => ({
    ...state,
    error: false,
});

export const sharedReducer = (state: SharedState, action: Action): SharedState =>
    reducerWithActionMappings(
        {
            [SHOW_SPINNER]: showSpinner,
            [HIDE_SPINNER]: hideSpinner,
            [SHOW_ERROR]: showError,
            [HIDE_ERROR]: hideError,
        },
        initialState.shared,
    )(state, action);
