import { OrganizationsPage } from 'ui/pages/organizations/OrganizationsPage';
import { ProcessesPage } from 'ui/pages/processes/ProcessesPage';
import ProcessDetails from 'ui/pages/processes/ProcessTabs';
import OrganizationsDetails from 'ui/pages/organizations/OrganizationsDetails';
import UsersPage from 'ui/pages/users/UsersPage';
import ConfigurationPage from 'ui/pages/configuration/ConfigurationPage';
import UserProfile from 'ui/pages/profile/UserProfile';
import HomePage from 'ui/pages/homepage/HomePage';
import ProcessMeasurementsPage from 'ui/pages/processmeasurements/ProcessMeasurementsPage';
import ActivityMeasurements from 'ui/pages/processmeasurements/ActivityMeasurements';
import { Role } from 'ui/api/gen';

export const routes = {
    DASHBOARD: {
        path: '/',
        component: HomePage,
        title: 'HomePage',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
    },
    ORGANIZATIONS: {
        path: '/organizations',
        component: OrganizationsPage,
        title: 'Organizations',
        roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
    },
    ORGANIZATION_DETAILS: {
        path: '/organizations/:id',
        component: OrganizationsDetails,
        title: 'Organizations details',
        roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
    },
    PROCESSES: {
        path: '/processes',
        component: ProcessesPage,
        title: 'Processes',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN],
    },
    SINGLE_PROCESSES: {
        path: '/processes/:id',
        component: ProcessDetails,
        title: 'Single Process',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN],
    },
    USERS: {
        path: '/users',
        component: UsersPage,
        title: 'Users',
        roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
    },
    USERS_DETAILS: {
        path: '/profile',
        component: UserProfile,
        title: 'Profile',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
    },
    CONFIGURATION: {
        path: '/configuration',
        component: ConfigurationPage,
        title: 'Configuration',
        roles: [Role.PM_MANAGER, Role.PM_ADMIN],
    },
    MEASUREMENT: {
        path: '/measurements',
        component: ProcessMeasurementsPage,
        title: 'Configuration',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN],
    },
    MEASUREMENT_DETAILS: {
        path: '/measurements/:id',
        component: ActivityMeasurements,
        title: 'Configuration',
        roles: [Role.PM_OPERATOR, Role.PM_MANAGER, Role.PM_ADMIN],
    },
};
