import { Route, Routes } from 'react-router-dom';
import { Container } from 'ui/layout/Container';
import { routes } from 'ui/routing';
import GlobalError from 'ui/pages/common/GlobalError';
import NotFound from 'ui/pages/common/NotFound';
import React from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';

export const RoutesWrapper = () => {
    const { state } = React.useContext(ApplicationContext);
    const { user } = state;
    return (
        <Routes>
            <Route path="/" element={<Container />}>
                {Object.keys(routes)
                    .filter((key) => {
                        const { roles } = routes[key];
                        // Check if the user has at least one role required by the route
                        return roles.some((role) => role === user.role);
                    })
                    .map((key) => {
                        const { path, component: Component } = routes[key];

                        return <Route key={path} path={path} element={<Component />} />;
                    })}
            </Route>
            <Route path="/error" element={<GlobalError />} />;
            <Route path="*" element={<NotFound />} />;
        </Routes>
    );
};
