import React from 'react';
import { Box } from '@mui/material';
import Activities from 'ui/pages/processes/Activities';

const ProcessConfig = ({ process }) => (
    <Box>
        <Activities process={process} />
    </Box>
);

export default ProcessConfig;
