import { TextField } from 'mui-rff';
import React from 'react';
import { useField } from 'react-final-form';

const ActivityDuration = () => {
    const {
        input: { value },
    } = useField('mandatory');

    return (
        <TextField
            variant="outlined"
            required
            type="number"
            fullWidth
            disabled={!value}
            id="duration"
            label="Времетраење во секунди"
            style={{ marginBottom: '10px' }}
            name="duration"
        />
    );
};

export default ActivityDuration;
