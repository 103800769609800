import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Button, MenuItem, Checkbox } from '@mui/material';
import { Autocomplete, AutocompleteData, makeValidate, Select, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { MaterialRef, Process, ProductRef } from 'ui/api/gen';
import { array, object, string } from 'yup';

interface Props {
    open: boolean;
    onSubmit: any;
    handleClose: () => void;
    process?: Process;
    products: ProductRef[];
    materials: MaterialRef[];
}

const AddProcess = ({ open, onSubmit, handleClose, process, products, materials }: Props) => {
    const items = React.useMemo(
        () =>
            products.map(({ id, name }) => ({
                id,
                name,
            })),
        [products],
    );

    const schema = object({
        name: string().required(),
        materialIds: array().of(string().required()).required(),
        productId: string().required(),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" disableEscapeKeyDown>
            <DialogTitle>{process ? 'Ажурирај процес' : 'Додади процес'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    initialValues={process}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField name="name" type="text" label="Име" margin="normal" fullWidth required />
                            <TextField
                                name="description"
                                type="text"
                                label="Опис на производот"
                                margin="normal"
                                fullWidth
                            />
                            <br />
                            <br />
                            <Autocomplete
                                label="Избери материјал"
                                name="materialIds"
                                multiple
                                freeSolo
                                options={materials}
                                required
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option: string | AutocompleteData) =>
                                    (option as AutocompleteData).name
                                }
                                disableCloseOnSelect
                                renderOption={(props, option: AutocompleteData, { selected }) =>
                                    option.inputValue ? (
                                        option.label
                                    ) : (
                                        <li {...props}>
                                            <Checkbox
                                                key={option?.inputValue}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )
                                }
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                            />{' '}
                            <Select
                                name="productId"
                                label="Селектирај производ"
                                required
                                formControlProps={{ margin: 'normal' }}
                            >
                                {items.map((product) => (
                                    <MenuItem value={product.id}>{product.name}</MenuItem>
                                ))}
                            </Select>
                            <br />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddProcess;
