import React, { useState } from 'react';
import { Typography, Grid, Chip, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/system';
import { useSpinner } from 'ui/hooks/spinner';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from 'ui/components/dialog/ConfirmationDialog';
import { Material, MaterialService, ProcessService, Product, ProductService, Role, Status } from 'ui/api/gen';
import { STATUS_LABEL } from 'ui/utils/enums';
import { useErrorHandler } from 'ui/utils/hooks';
import { ExpandMore } from '@mui/icons-material';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const Label = styled('div')`
    flex: 1;
`;

const ProcessDetails = ({ process, forceUpdate }) => {
    const [product, setProduct] = useState<Product>();
    const [materials, setMaterials] = useState<Material[]>([]);

    const [show, setShow] = React.useState(false);

    const handleError = useErrorHandler();
    const withSpinner = useSpinner();

    const { id } = useParams();

    const { state } = React.useContext(ApplicationContext);

    const { user } = state;

    const toggleConfirmationOverlay = React.useCallback(() => {
        setShow((val) => !val);
    }, []);

    const onConfirm = React.useCallback(() => {
        const update = async () => {
            try {
                if (process) {
                    await ProcessService.patchProcesses(process?.id, { status: Status.ACTIVE });

                    forceUpdate();
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            } finally {
                toggleConfirmationOverlay();
            }
        };

        withSpinner(update);
    }, [process, withSpinner, handleError, forceUpdate, toggleConfirmationOverlay]);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (process) {
                    const { productId } = process;

                    const productResponse = await ProductService.getProducts1(productId);
                    setProduct(productResponse);

                    const promises = process?.materialIds.map((item) => MaterialService.getMaterials1(item));
                    const results = await Promise.all(promises);
                    setMaterials(results);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, id, process, handleError]);

    if (!process) {
        return null;
    }

    return (
        <>
            <ConfirmationDialog onConfirm={onConfirm} open={show} onCancel={toggleConfirmationOverlay} />

            <Accordion elevation={0} expanded>
                <AccordionSummary
                    sx={{ alignItems: 'center' }}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                >
                    <Label>{process.name}</Label>
                    <Chip
                        color={process.status === Status.DRAFT ? undefined : 'success'}
                        label={STATUS_LABEL[process.status]}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2">
                                <strong>Продукт:</strong> {product?.name}
                            </Typography>
                        </Grid>

                        {process.description && (
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    <strong>Опис на процесот:</strong> {process.description}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>Материјали:</strong>
                                {materials.map((material) => (
                                    <Chip key={material.id} label={material.name} sx={{ m: 1 }} />
                                ))}
                            </Typography>
                        </Grid>
                    </Grid>
                    {user?.role && [Role.PM_MANAGER, Role.PM_ADMIN].includes(user.role) && (
                        <Button
                            onClick={toggleConfirmationOverlay}
                            color="success"
                            variant="contained"
                            disabled={process?.status === Status.ACTIVE}
                        >
                            Активирај
                        </Button>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ProcessDetails;
