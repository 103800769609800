export const SHOW_SPINNER = 'APP@SHARED.SPINNER.SHOW';
export const HIDE_SPINNER = 'APP@SHARED.SPINNER.HIDE';
export const SHOW_ERROR = 'APP@SHARED.ERROR.SHOW';
export const HIDE_ERROR = 'APP@SHARED.ERROR.HIDE';

export const showSpinner = () => ({
    type: SHOW_SPINNER,
});

export const hideSpinner = () => ({
    type: HIDE_SPINNER,
});

export const showError = () => ({
    type: SHOW_ERROR,
});

export const hideError = () => ({
    type: HIDE_ERROR,
});
