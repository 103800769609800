/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Material } from '../models/Material';
import type { PageableProducts } from '../models/PageableProducts';
import type { Product } from '../models/Product';
import type { ProductRef } from '../models/ProductRef';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductService {
    /**
     * Updates the product
     * Updates the product
     * @param requestBody Updates product by id
     * @returns Material Successful operation
     * @throws ApiError
     */
    public static putMaterials(
        requestBody: Material,
    ): CancelablePromise<Material> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all products of organization
     * Get all products of organization
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param organizationId id of the organization
     * @param name name of the product
     * @returns PageableProducts Get all products of an organization
     * @throws ApiError
     */
    public static getProducts(
        size?: number,
        page?: number,
        sort?: string,
        organizationId?: string,
        name?: string,
    ): CancelablePromise<PageableProducts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'organizationId': organizationId,
                'name': name,
            },
        });
    }
    /**
     * Create an product for a organization
     * Create an product for a organization
     * @param requestBody
     * @returns Product Successful operation
     * @throws ApiError
     */
    public static postProducts(
        requestBody: Product,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the product
     * Updates the product
     * @param requestBody Updates product by id
     * @returns Product Successful operation
     * @throws ApiError
     */
    public static putProducts(
        requestBody: Product,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all products of organization
     * Get all products of organization
     * @param organizationId
     * @returns ProductRef Get all products of an organization
     * @throws ApiError
     */
    public static getProductsRef(
        organizationId?: string,
    ): CancelablePromise<Array<ProductRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/ref',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Delete an product
     * Delete an product
     * @param id Product id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteProducts(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get a product by id
     * Get a product by id
     * @param id Product id to retrieve
     * @returns Product Successful operation
     * @throws ApiError
     */
    public static getProducts1(
        id: string,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{id}',
            path: {
                'id': id,
            },
        });
    }
}
