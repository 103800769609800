import { mkMK as mkMKCore, Localization } from '@mui/material/locale';
import { GridLocaleText } from '@mui/x-data-grid';
import { getGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';

const bgBGGrid: Partial<GridLocaleText> = {
    // Root
    noRowsLabel: 'Нема резултат',
    noResultsOverlayLabel: 'Нема резултат.',

    // Density selector toolbar button text
    toolbarDensity: 'Гъстота',
    toolbarDensityLabel: 'Гъстота',
    toolbarDensityCompact: 'Компактна',
    toolbarDensityStandard: 'Стандартна',
    toolbarDensityComfortable: 'Комфортна',

    // Columns selector toolbar button text
    toolbarColumns: 'Колони',
    toolbarColumnsLabel: 'Покажи селектора на колони',

    // Filters toolbar button text
    toolbarFilters: 'Филтри',
    toolbarFiltersLabel: 'Покажи Филтрите',
    toolbarFiltersTooltipHide: 'Сокриј филтри',
    toolbarFiltersTooltipShow: 'Покажи филтри',
    toolbarFiltersTooltipActive: (count) => `${count} активни филтри`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Пребарај',
    toolbarQuickFilterLabel: 'Търсене',
    toolbarQuickFilterDeleteIconLabel: 'Изчисти',

    // Export selector toolbar button text
    toolbarExport: 'Изтегли',
    toolbarExportLabel: 'Изтегли',
    toolbarExportCSV: 'Изтегли като CSV',
    toolbarExportPrint: 'Принтиране',
    toolbarExportExcel: 'Изтегли като Excel',

    // Filter panel text
    filterPanelAddFilter: 'Добави Филтър',
    filterPanelRemoveAll: 'Премахни всички',
    filterPanelDeleteIconLabel: 'Изтрий',
    filterPanelLogicOperator: 'Логически оператор',
    filterPanelOperator: 'Оператори',
    filterPanelOperatorAnd: 'И',
    filterPanelOperatorOr: 'Или',
    filterPanelColumns: 'Колони',
    filterPanelInputLabel: 'Вредност',
    filterPanelInputPlaceholder: 'Вредност на филтер',

    // Filter operators text
    filterOperatorContains: 'содржи',
    filterOperatorEquals: 'еднакво',
    filterOperatorStartsWith: 'започнува со',
    filterOperatorEndsWith: 'завршува со',
    filterOperatorIs: 'е',
    filterOperatorNot: 'не е',
    filterOperatorAfter: 'е после',
    filterOperatorOnOrAfter: 'е на или после',
    filterOperatorBefore: 'е преди',
    filterOperatorOnOrBefore: 'е на или пред',
    filterOperatorIsEmpty: 'е празен',
    filterOperatorIsNotEmpty: 'не е празен',
    filterOperatorIsAnyOf: 'е еден од',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',

    // Header filter operators text
    headerFilterOperatorContains: 'Содржи',
    headerFilterOperatorEquals: 'Еднакво',
    headerFilterOperatorStartsWith: 'Започнува со',
    headerFilterOperatorEndsWith: 'Завршува со',
    headerFilterOperatorIs: 'Еднакво е на',
    headerFilterOperatorNot: 'Не се равнява на',
    headerFilterOperatorAfter: 'След',
    headerFilterOperatorOnOrAfter: 'След (включително)',
    headerFilterOperatorBefore: 'Преди',
    headerFilterOperatorOnOrBefore: 'Преди (включително)',
    headerFilterOperatorIsEmpty: 'Празен',
    headerFilterOperatorIsNotEmpty: 'Не е празен',
    headerFilterOperatorIsAnyOf: 'Всичко от',
    'headerFilterOperator=': 'Равно',
    'headerFilterOperator!=': 'Различно',
    'headerFilterOperator>': 'По-голямо от',
    'headerFilterOperator>=': 'По-голямо или равно на',
    'headerFilterOperator<': 'По-малко от',
    'headerFilterOperator<=': 'По-малко или равно на',

    // Filter values text
    filterValueAny: 'всякакви',
    filterValueTrue: 'вярно',
    filterValueFalse: 'невярно',

    // Column menu text
    columnMenuLabel: 'Мени',
    columnMenuShowColumns: 'Покажи колоните',
    columnMenuManageColumns: 'Управување со колони',
    columnMenuFilter: 'Филтри',
    columnMenuHideColumn: 'Сокриј',
    columnMenuUnsort: 'Откажи сортирање',
    columnMenuSortAsc: 'Подреди по растечки редослед',
    columnMenuSortDesc: 'Подреди по опаѓачки редослед',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count} активни филтри`,
    columnHeaderFiltersLabel: 'Покажи Филтрите',
    columnHeaderSortIconLabel: 'Сортирај',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1 ? `${count.toLocaleString()} избрани редови` : `${count.toLocaleString()} избран ред`,

    // Total row amount footer text
    footerTotalRows: 'Вкупно редови:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} од ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Избор на квадратче',
    checkboxSelectionSelectAllRows: 'Изберете ги сите редови',
    checkboxSelectionUnselectAllRows: 'Деселектирај ги сите редови',
    checkboxSelectionSelectRow: 'Избери ред',
    checkboxSelectionUnselectRow: 'Деселектирај го редот\n',

    // Boolean cell text
    booleanCellTrueLabel: 'да',
    booleanCellFalseLabel: 'не',

    // Actions cell more text
    actionsCellMore: 'още',

    // Column pinning text
    pinToLeft: 'Закачи в ляво',
    pinToRight: 'Закачи в дясно',
    unpin: 'Откачи',

    // Tree Data
    treeDataGroupingHeaderName: 'Група',
    treeDataExpand: 'види деца',
    treeDataCollapse: 'скриј ги децата',

    // Grouping columns
    groupingColumnHeaderName: 'Група',
    groupColumn: (name) => `Групирај по ${name}`,
    unGroupColumn: (name) => `Спри групиране по ${name}`,

    // Master/detail
    detailPanelToggle: 'Превключване на панела с детайли',
    expandDetailPanel: 'Разгъване',
    collapseDetailPanel: 'Свиване',

    // Row reordering text
    rowReorderingHeaderName: 'Подредба на редови',

    // Aggregation
    aggregationMenuItemHeader: 'Агрегиране',
    aggregationFunctionLabelSum: 'сума',
    aggregationFunctionLabelAvg: 'срст',
    aggregationFunctionLabelMin: 'мин',
    aggregationFunctionLabelMax: 'макс',
    aggregationFunctionLabelSize: 'размер',
};

// @ts-ignore
export const mkMK: Localization = getGridLocalization(bgBGGrid, mkMKCore);
