import * as React from 'react';
import { Button } from '@mui/material';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { Role } from 'ui/api/gen';
import AddIcon from '@mui/icons-material/Add';

type Props = {
    title: string;
    onClick: () => void;
    roles: Role[];
};

const RestrictedAddButton = ({ onClick, title, roles }: Props) => {
    const {
        state: { user },
    } = React.useContext(ApplicationContext);

    if (user?.role && !roles.includes(user.role)) {
        return null;
    }

    return (
        <Button
            color="secondary"
            variant="contained"
            onClick={onClick}
            style={{ margin: '10px 0' }}
            startIcon={<AddIcon />}
        >
            {title}
        </Button>
    );
};

export default RestrictedAddButton;
