import * as React from 'react';
import { TextField } from '@mui/material';
import { useSpinner } from 'ui/hooks/spinner';
import { useErrorHandler } from 'ui/utils/hooks';
import { ActivityMeasurementService } from 'ui/api/gen';

const Quantity = ({ measurement, disabled }) => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();

    const handleUpdate = React.useCallback(
        ({ target }) => {
            const callback = async () => {
                try {
                    await ActivityMeasurementService.patchProcessesMeasurementsActivitiesMeasurements(measurement?.id, {
                        quantity: target?.value as number,
                    });
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [measurement, withSpinner, handleError],
    );

    return (
        <TextField
            onBlur={handleUpdate}
            id="outlined-number"
            label="Количина"
            type="number"
            variant="standard"
            defaultValue={measurement?.quantity}
            disabled={!measurement || disabled}
        />
    );
};

export default Quantity;
