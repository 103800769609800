import React from 'react';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { makeValidate, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { object, string } from 'yup';
import { Product } from 'ui/api/gen';
import { ApplicationContext } from 'ui/context/ApplicationContext';

type Props = {
    open: boolean;
    onSubmit: (values: any) => void;
    handleClose: () => void;
    product?: Product;
};

const AddProduct = ({ open, handleClose, onSubmit, product }: Props) => {
    const { state } = React.useContext(ApplicationContext);
    const {
        organization: { id },
    } = state;

    const schema = object({
        name: string().required(),
    });

    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Додади продукт</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={product || { organizationId: id }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Назив на продуктот"
                                        style={{ marginBottom: '15px', borderRadius: 0 }}
                                        name="name"
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddProduct;
