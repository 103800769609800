import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { makeValidate, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { object, string } from 'yup';
import { ActivityMeasurement } from 'ui/api/gen';

interface Props {
    open: boolean;
    measurement?: ActivityMeasurement;
    onSubmit: any;
    handleClose: () => void;
}

const DurationOverlay = ({ open, measurement, onSubmit, handleClose }: Props) => {
    const schema = object({
        duration: string().required(),
    });

    console.log(measurement);
    const validate = makeValidate(schema);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" disableEscapeKeyDown>
            <DialogTitle>Ажурирај времетраење</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{ duration: measurement?.duration }}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                type="number"
                                id="duration"
                                label="Времетраење"
                                name="duration"
                            />
                            <br />
                            <br />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DurationOverlay;
