import React from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { hideSpinner, showSpinner } from 'ui/context/shared/actions';

export function useSpinner<T>() {
    const { dispatch } = React.useContext(ApplicationContext);

    return React.useCallback(
        async (asyncOperation: () => Promise<T>): Promise<T> => {
            async function invokeWithSpinner(fn: () => Promise<T>): Promise<T> {
                try {
                    dispatch(showSpinner());
                    return await fn();
                } finally {
                    dispatch(hideSpinner());
                }
            }
            return invokeWithSpinner(asyncOperation);
        },
        [dispatch],
    );
}
