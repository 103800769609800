/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessMeasurementStatistic } from '../models/ProcessMeasurementStatistic';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessMeasurementStatisticsService {
    /**
     * Get process measurement statistics
     * Get process measurement statistics
     * @param organizationId Id of the organization
     * @param processId Id of the process
     * @param processMeasurementId Id of the process measurement
     * @returns ProcessMeasurementStatistic Successful operation
     * @throws ApiError
     */
    public static getProcessMeasurementStatistics(
        organizationId?: string,
        processId?: string,
        processMeasurementId?: string,
    ): CancelablePromise<Array<ProcessMeasurementStatistic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-measurement-statistics',
            query: {
                'organizationId': organizationId,
                'processId': processId,
                'processMeasurementId': processMeasurementId,
            },
        });
    }
}
