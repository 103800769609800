import { Box } from '@mui/material';
import React from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { initialFilters } from 'ui/utils/datagrid';
import {
    MaterialReactTable,
    MRT_ColumnFiltersState,
    MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import EmptyState from 'ui/components/svg/EmptyState';

type Props = {
    rows: any[] | undefined;
    columns: any[];
    pageIndex: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    setFilters: any;
    initialColumnFilters?: any;
};

const TableComponent = ({
    rows = [],
    columns,
    pageSize,
    pageCount,
    rowCount = 0,
    pageIndex,
    setFilters,
    initialColumnFilters = [],
}: Props) => {
    const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>(initialColumnFilters);
    const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
    const [pagination, setPagination] = React.useState({
        pageIndex,
        pageSize,
    });

    const { state } = React.useContext(ApplicationContext);
    const {
        shared: { spinner },
    } = state;

    const onSortModelChange = React.useCallback(
        (model: any) => {
            const elem = model[0];

            if (elem) {
                const order = elem.desc ? 'desc' : 'asc';
                setFilters((prevFilters) => ({ ...prevFilters, sort: `${elem.id},${order}` }));
            } else {
                setFilters((prevFilters) => ({
                    ...initialFilters,
                    columnFilters: prevFilters.columnFilters,
                }));
            }
        },
        [setFilters],
    );

    const onPaginationModelChange = React.useCallback(
        (model: any) => {
            setFilters((prevFilters) => ({ ...prevFilters, ...model }));
        },
        [setFilters],
    );

    React.useEffect(() => {
        onSortModelChange(sorting);
    }, [onSortModelChange, sorting]);

    React.useEffect(() => {
        setFilters((prevFilters) => ({ ...prevFilters, columnFilters }));
    }, [columnFilters]);

    React.useEffect(() => {
        onPaginationModelChange(pagination);
    }, [pagination, pagination, onPaginationModelChange]);

    const table = useMaterialReactTable({
        columns,
        data: rows,
        manualSorting: true,
        manualFiltering: true,
        manualPagination: true,
        enableGlobalFilter: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        enableFilterMatchHighlighting: false,
        enableColumnResizing: false,
        muiTablePaperProps: {
            elevation: 0,
        },
        pageCount,
        rowCount,
        state: { sorting, showProgressBars: !!spinner, columnFilters, pagination },
        initialState: { density: 'compact', showColumnFilters: true, sorting: [{ id: 'createdAt', desc: false }] },
        muiLinearProgressProps: ({ isTopToolbar }) => ({
            color: 'secondary',
            sx: { display: isTopToolbar ? 'none' : 'block' },
        }),
        localization: {
            rowsPerPage: 'Редови по страница',
            noRecordsToDisplay: 'Нема записи',
            sortByColumnAsc: 'Сортирај',
            sortByColumnDesc: 'Сортирај',
            sortedByColumnAsc: 'Сортирај',
            hideAll: 'Сокриј ги сите',
            showAll: 'Прикажи ги сите',
            sortedByColumnDesc: 'Сортирај',
            filterByColumn: 'Филтрирај',
            showHideColumns: 'Прикажи/Сокриј колони',
            showHideFilters: 'Прикажи/Сокриј филтри',
            toggleFullScreen: 'Прикажи на целосен екран',
        },
        enableStickyHeader: true,
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
            },
        }),
        renderEmptyRowsFallback: EmptyState,
    });

    return (
        <Box>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TableComponent;
