import * as React from 'react';
import { useSpinner } from 'ui/hooks/spinner';
import { PageableMaterials, PageableProducts, Product, ProductService, Role } from 'ui/api/gen';
import { formatDateToISOFormat } from 'ui/utils/date';
import AddProduct from 'ui/pages/configuration/products/AddProduct';
import { useErrorHandler, useIsMounted } from 'ui/utils/hooks';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { initialFilters } from 'ui/utils/datagrid';
import TableComponent from 'ui/components/grid/TableComponent';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const Products = () => {
    const [filters, setFilters] = React.useState(initialFilters);
    const [show, setShow] = React.useState(false);

    const handleError = useErrorHandler();
    const withSpinner = useSpinner();
    const isMounted = useIsMounted();
    const [current, setCurrent] = React.useState<Product | undefined>();
    const [pageable, setPageable] = React.useState<PageableMaterials>();

    const { state } = React.useContext(ApplicationContext);
    const { organization } = state;

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const callback = async () => {
                try {
                    await ProductService.deleteProducts(id);

                    const response: PageableProducts = await ProductService.getProducts();
                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [isMounted, withSpinner, handleError],
    );

    const handleUpdate = React.useCallback(
        ({ original: { id } }) => {
            setCurrent(pageable?.result.find((elem) => elem.id === id));
            toggleOverlay();
        },
        [pageable, toggleOverlay],
    );

    const actions = React.useMemo(
        () => ({
            UPDATE: { label: 'Ажурирај', callback: handleUpdate },
            DELETE: { label: 'Избриши', callback: handleDelete },
        }),
        [handleDelete, handleUpdate],
    );

    const columns = React.useMemo(
        () => [
            { accessorKey: 'name', header: 'Назив', flex: 3 },
            {
                accessorKey: 'createdAt',
                header: 'Датум',
                enableColumnFilter: false,
                flex: 3,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'actions',
                headerName: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 1,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions],
    );

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, name, createdAt }) => ({
                id,
                name,
                createdAt,
            })),
        [pageable],
    );

    const handleClose = React.useCallback(() => {
        setShow(false);
        setCurrent(undefined);
    }, []);

    const handleAddProduct = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    const callback = values.id ? ProductService.putProducts : ProductService.postProducts;
                    await callback(values);

                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const response: PageableProducts = await ProductService.getProducts(
                        pageSize,
                        pageIndex,
                        sort,
                        organization?.id,
                        columnFilters.find((i) => i.id === 'name')?.value,
                    );
                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    handleClose();
                }
            };
            withSpinner(save);
        },
        [organization, handleClose, isMounted, withSpinner, filters, handleError],
    );

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const response: PageableProducts = await ProductService.getProducts(
                    pageSize,
                    pageIndex,
                    sort,
                    organization?.id,
                    columnFilters.find((i) => i.id === 'name')?.value,
                );
                if (isMounted) {
                    setPageable(response);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, isMounted, filters, handleError, organization?.id]);

    return (
        <div>
            <RestrictedAddButton
                onClick={toggleOverlay}
                title="Додади продукт"
                roles={[Role.PM_ADMIN, Role.PM_MANAGER]}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={pageable?.page?.totalElements}
                pageCount={pageable?.page?.totalPages}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
            <AddProduct open={show} handleClose={handleClose} onSubmit={handleAddProduct} product={current} />
        </div>
    );
};

export default Products;
