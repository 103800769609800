/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invitation } from '../models/Invitation';
import type { PageableInvitations } from '../models/PageableInvitations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvitationService {
    /**
     * Get invitations
     * Get invitations
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param organizationId the id of the organization
     * @param role role of the user
     * @param email email of the organization
     * @returns PageableInvitations Successful operation
     * @throws ApiError
     */
    public static getInvitations(
        size?: number,
        page?: number,
        sort?: string,
        organizationId?: string,
        role?: string,
        email?: string,
    ): CancelablePromise<PageableInvitations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invitations',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'organizationId': organizationId,
                'role': role,
                'email': email,
            },
        });
    }
    /**
     * Create an invitation for a user
     * Create an invitation for a user
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static postInvitations(
        requestBody: Invitation,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invitations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an invitation
     * Delete an invitation
     * @param id Invitation id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteInvitations(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invitations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Resend an invitation
     * Resend an invitation
     * @param id Invitation id to delete
     * @returns void
     * @throws ApiError
     */
    public static putInvitations(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invitations/{id}',
            path: {
                'id': id,
            },
        });
    }
}
