import { ApplicationState } from 'ui/context/types';

export const initialState: ApplicationState = {
    shared: {
        spinner: 0,
        error: false,
    },
    // @ts-ignore
    user: {},
    // @ts-ignore
    organization: {},
};
