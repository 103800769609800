import React from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Tile = styled(Box)(({ theme }) => ({
    minWidth: '300px',
    height: '200px',
    marginTop: '10px',
    flex: '1 1 200px',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 10px 10px darkgray',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '5px',
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    transition: 'background-color 0.4s ease-in-out',
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    '&:hover .hoverButton, &:hover .hoverBackground': {
        transform: 'translateY(0)',
        opacity: 1,
    },
}));

const HoverLayer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    backgroundColor: theme.palette.common.white, // Background turns white
    transition: 'transform 0.4s ease-in-out, opacity 0.4s ease-in-out', // Smooth slide and opacity
    transform: 'translateY(100%)', // Hidden by default (off-screen)
    opacity: 0, // Hidden initially
    zIndex: 1, // Background layer behind text and icon
}));

const ButtonLayer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    transition: 'transform 0.4s ease-in-out, opacity 0.4s ease-in-out',
    transform: 'translateY(100%)', // Hidden below initially
    opacity: 0, // Hidden initially
    zIndex: 2, // Button above the background
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    zIndex: 3,
}));

const IconContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    zIndex: 3,
}));

const TileComponent = ({ icon: IconComponent, title, route }) => {
    const navigate = useNavigate();

    const handleNavigate = React.useCallback(() => {
        navigate(route);
    }, [route]);
    return (
        <Tile>
            <HoverLayer className="hoverBackground" />

            <IconContainer>
                <IconComponent style={{ fontSize: '40px' }} />
            </IconContainer>
            <Title>{title}</Title>

            {/* Button that transitions up on hover */}
            <ButtonLayer className="hoverButton">
                <Button variant="contained" color="primary" onClick={handleNavigate}>
                    Отвори
                </Button>
            </ButtonLayer>
        </Tile>
    );
};

export default TileComponent;
