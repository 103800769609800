import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const NotFoundPage = () => {
    const theme = useTheme();
    const navigate = useNavigate(); // Hook for navigation

    const handleHomeClick = () => {
        navigate('/'); // Redirect to home page
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
            }}
        >
            <Typography
                variant="h1"
                component="div"
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: '8rem',
                    fontWeight: 'bold',
                    mb: 1,
                }}
            >
                404
            </Typography>

            <Typography
                variant="h4"
                component="div"
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: '2rem',
                    fontWeight: 'bold',
                }}
            >
                Страницата не е пронајдена
            </Typography>

            <Button
                variant="contained"
                color="primary"
                startIcon={<HomeIcon />}
                onClick={handleHomeClick}
                sx={{
                    mb: 4, // Margin below the button
                }}
            >
                Почетна
            </Button>

            {/* Colored section at the bottom */}
            <Box
                sx={{
                    height: '33%',
                    width: '100%',
                    backgroundColor: theme.palette.primary.main,
                }}
            />
        </Box>
    );
};

export default NotFoundPage;
