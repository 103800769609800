import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Chip, Checkbox, MenuItem, Button } from '@mui/material';
import { Autocomplete, AutocompleteData, makeValidate, Select, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import {
    Activity,
    ActivityService,
    MaterialRef,
    PageableActivities,
    Process,
    ProcessService,
    ProductRef,
    Status,
} from 'ui/api/gen';
import { array, object, string } from 'yup';
import { MANDATORY } from 'ui/utils/enums';
import styled from '@emotion/styled';
import { useSpinner } from 'ui/hooks/spinner';
import { useErrorHandler } from 'ui/utils/hooks';
import { ColumnFilter } from 'ui/utils/datagrid';
import TreeViewTableComponent from 'ui/components/grid/TreeViewTableComponent';

const Text = styled('span')`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
    min-height: 50px;
`;

interface Props {
    open: boolean;
    onSubmit: any;
    handleClose: () => void;
    process?: Process;
    products: ProductRef[];
    materials: MaterialRef[];
}

const initial = {
    pageIndex: 0,
    pageSize: 10,
    sort: 'createdAt,desc',
    columnFilters: [] as ColumnFilter[],
};

const CopyProcess = ({ open, handleClose, process, materials, products }: Props) => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [activities, setActivities] = React.useState<PageableActivities>();
    const [filters, setFilters] = React.useState(initial);

    const [selection, setSelection] = React.useState({});
    const schema = object({
        name: string().required(),
        materialIds: array().of(string().required()).required(),
        productId: string().required(),
    });

    const handleSubmitCallback = React.useCallback(
        (values: Process) => {
            const save = async () => {
                try {
                    // @ts-ignore
                    await ProcessService.postProcessesCopy(values.id, {
                        description: values.description,
                        name: values.name,
                        materialIds: values.materialIds,
                        productId: values.productId,
                        status: Status.DRAFT,
                        activities: Object.keys(selection).filter((key) => selection[key]),
                    });
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(save);
        },
        [withSpinner, selection, handleError],
    );

    const items = React.useMemo(
        () =>
            products.map(({ id, name }) => ({
                id,
                name,
            })),
        [products],
    );

    const validate = makeValidate(schema);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                grow: 20,
                header: 'Фаза',
                enableColumnFilter: false,
                enableSorting: false,
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
                Cell: ({ cell, row }) => <div style={{ paddingLeft: `${row.depth * 25}px` }}>{cell.getValue()}</div>,
            },

            {
                grow: 1,
                accessorKey: 'mandatory',
                header: 'Статус',
                filterVariant: 'checkbox',
                enableColumnFilter: false,
                enableSorting: false,
                Header: ({ column }) => <Text>{column.columnDef.header}</Text>,
                Cell: ({ row }) => {
                    const label = MANDATORY[row.original.mandatory];
                    const color = row.original.mandatory ? 'success' : undefined;

                    if (row.original.children?.length !== 0) {
                        return null;
                    }

                    return <Chip color={color} label={label} />;
                },
            },
        ],
        [],
    );

    React.useEffect(() => {
        const initialize = async () => {
            try {
                if (process?.id) {
                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const pageableActivities = await ActivityService.getProcessesActivities(
                        process?.id,
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'name')?.value,
                        columnFilters.find((i) => i.id === 'mandatory')?.value,
                    );
                    setActivities(pageableActivities);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [process?.id, withSpinner, handleError, filters, process]);

    const data = React.useMemo(() => {
        const transformActivity = (activity: Activity) => {
            if (activity.children?.length !== 0) {
                return {
                    id: activity.id,
                    name: activity.name,
                    duration: activity.duration,
                    average: activity.avgDuration,
                    mandatory: activity.mandatory,
                    description: activity.description,
                    parentId: activity.parentId,
                    children: activity.children?.length ? activity.children.map(transformActivity) : [],
                };
            }

            return {
                id: activity.id,
                name: activity.name,
                description: activity.description,
                duration: activity.duration,
                average: activity.avgDuration,
                mandatory: activity.mandatory,
                parentId: activity.parentId,
                children: activity.children?.length ? activity.children.map(transformActivity) : [],
            };
        };

        return activities?.result.map(transformActivity);
    }, [activities]);
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
            <DialogTitle>Копирај процес</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={handleSubmitCallback}
                    initialValues={process}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField name="name" type="text" label="Име" margin="normal" fullWidth required />
                            <TextField
                                name="description"
                                type="text"
                                label="Опис на производот"
                                margin="normal"
                                fullWidth
                            />
                            <br />
                            <br />
                            <Autocomplete
                                label="Избери материјал"
                                name="materialIds"
                                multiple
                                freeSolo
                                options={materials}
                                required
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option: string | AutocompleteData) =>
                                    (option as AutocompleteData).name
                                }
                                disableCloseOnSelect
                                renderOption={(props, option: AutocompleteData, { selected }) =>
                                    option.inputValue ? (
                                        option.label
                                    ) : (
                                        <li {...props}>
                                            <Checkbox
                                                key={option?.inputValue}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )
                                }
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                            />{' '}
                            <Select
                                name="productId"
                                label="Селектирај производ"
                                required
                                formControlProps={{ margin: 'normal' }}
                            >
                                {items.map((product) => (
                                    <MenuItem value={product.id}>{product.name}</MenuItem>
                                ))}
                            </Select>
                            <br />
                            <TreeViewTableComponent
                                pageSize={filters.pageSize}
                                pageIndex={filters.pageIndex}
                                pageCount={activities?.page?.totalPages}
                                rowCount={activities?.page?.totalElements}
                                rows={data}
                                columns={columns}
                                enableRowSelection
                                setRowSelectionCallback={setSelection}
                                initial={initial}
                                setFilters={setFilters}
                            />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={handleClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default CopyProcess;
