import { reducerWithActionMappings } from 'ui/context/reducers';
import { Action } from 'ui/context/types';
import { initialState } from 'ui/context/initialState';
import { SET_ORGANIZATION } from 'ui/context/organizations/actions';
import { SetOrganizationAction } from 'ui/context/organizations/types';
import { OrganizationRef } from 'ui/api/gen';

const setOrganization = (state: OrganizationRef, action: SetOrganizationAction): OrganizationRef => action.organization;

export const organizationsReducer = (state: OrganizationRef, action: Action): OrganizationRef =>
    reducerWithActionMappings(
        {
            [SET_ORGANIZATION]: setOrganization,
        },
        initialState.organization,
        // @ts-ignore
    )(state, action);
